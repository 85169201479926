import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import axios from "axios"
import { baseUrl } from 'config.js'


export const api = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL}),
    reducerPath: "adminApi",
    tagTypes: ['Facebook', "Instagram"],
    endpoints: (build) => ({
        getFacebook: build.query({
            query:() => "general/facebook",
            providesTags: ["Facebook"]
        }),

        getInstagram: build.query({
            query:() => "general/instagram",
            providesTags:['Instagram']
        })
    })
})

export const { useGetFacebookQuery, 
    useGetInstagramQuery 
} = api



export const getIntervalData = (org) => {
    return axios.get(`${baseUrl}/general/intervalData/${org}`)
}

// this api may be deprecationateid, will keep this here for now
export const getAggregateData = (org) => {
    return axios.get(`${baseUrl}/general/aggregateData/${org}`)
}

// APIs for dashboard
export const getTodayDashboardData = (org, channel) => {
    return axios.get(`${baseUrl}/general/todayDashboardData/${org}`, { 
        params: { 
            channel: JSON.stringify(channel) 
        }
    });
}

export const getIntervalDailyDashboardData = (org, channel) => {
    return axios.get(`${baseUrl}/general/intervalDailyDashboardData/${org}`, { 
        params: { 
            channel: JSON.stringify(channel) 
        }
    });
}

export const getDailyDashboardData = (org, channel) => {
    return axios.get(`${baseUrl}/general/dailyDashboardData/${org}`, { 
        params: { 
            channel: JSON.stringify(channel) 
        }
    });
}

export const getBestPostByChannel = (org,channel) => {
    return axios.get(`${baseUrl}/general/best-post/${org}`,{ 
        params: { 
            channel: JSON.stringify(channel) 
        }
    });
}

// API for Instagram Posts
// Get all post data
export const getInstagramPosts = (org) => {
    return axios.get(`${baseUrl}/general/instagram/${org}`)
}


// APIs for Facebook Posts 
// Get all post data
export const getFacebookPosts = (org, channel) => {
    return axios.get(`${baseUrl}/general/facebook/${org}/${channel}`)
}

// Update post attribute
export const sendFacebookPostAttribute = (data,org, channel) => {
    return axios.post(`${baseUrl}/general/updateFacebookPostAttribute/${org}/${channel}`,data)
}

// Remove post attribtue
export const deleteFacebookPostAttribute = (org, data, channel) => {
    return axios.post(`${baseUrl}/general/deleteFacebookPostAttribute/${org}/${channel}`,data)
}

// update linkedin page token
export const sendLinkedinPageToken = (data) => {
    return axios.post(`${baseUrl}/auth/updateLinkedinPageToken`,data)
}

// reset password
export const resetPassword = (data) => {
    return axios.post(`${baseUrl}/auth/reset-password`, data)
}

// fetch comments given channel and post id
export const fetchComments = (data) => {
    return axios.post(`${baseUrl}/general/fetch-post-comments`,
        data
    )
}


// reply to comment
export const replyToCommentGivenACommentId = ({commentId, channel, org, comment, postId}) => {
    return axios.post(`${baseUrl}/general/reply-to-comment-given-a-comment-id`,
        {
            commentId: commentId,
            channel: channel,
            org: org,
            comment: comment,
            postId: postId
        }
    )
}

// fetch attributes info
export const fetchAllAttributesBasicMetrics = ({org, channels}) => {
    return axios.post(`${baseUrl}/general/fetch-all-attributes-basic-metrics`,
    {
        org: org,
        channels: channels
    })
}

// fetch page access token
export const fetchPageAccessToken = (org,channel) => {
    return axios.post(`${baseUrl}/general/fetch-page-access-token/${org}/${channel}`)
}

// create post
export const createPost = (data) => {
    return axios.post(`${baseUrl}/general/create-post`, data)
}

// create post pending request
export const controllerCreatedPost = ({action, fields, data, query, org}) => {
    return axios.post(`${baseUrl}/general/controller-created-post`, {
        action: action,
        fields: fields,
        data: data,
        query:query,
        org:org
    })
}