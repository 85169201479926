import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import {TroubleshootOutlined, VisibilityOutlined, ThumbUpOutlined, CallMadeOutlined, TouchAppOutlined, ChatBubbleOutlineOutlined } from '@mui/icons-material'
import { parseAndFormatNumber } from 'lib/helperFunction'

import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

/**
 * `PostMetricsIcons` is a component that displays a set of icons with associated metrics. 
 *  If theres no metrics, the icon will not be displayed. 
 *  Tooltip param is optional, default is true. On hover, the tooltip will display the metric label
 * Comparison param is optional, default is false. If true, the comparison value will be displayed with arrows 
 * up or down depending on the value, absolute difference and percentage difference
 *
 * @component
 * @param {Object} props The properties object.
 * @param {number} props.impression The number of impressions.
 * @param {number} props.like The number of likes.
 * @param {number} props.comment The number of comments.
 * @param {number} props.share The number of shares.
 * @param {number} props.save The number of saves.
 * @param {number} props.gravity The gravity value.
 * @param {boolean} [props.showTooltip=true] A flag indicating whether tooltips should be shown.
 * @param {boolean} [props.comparison=false] A flag indicating whether comparison should be shown.
 * @returns {React.Element} A React element that represents the `PostMetricsIcons` component.
 */

const PostMetricsIcons = ({
    data,
    impression,
    like,
    comment,
    share, 
    save,
    gravity,
    showTooltip=true,
    comparison=false,
    comparisonImpression,
    comparisonLike,
    comparisonComment,
    comparisonShare,
    comparisonSave,
    comparisonGravity

}) => {
    const metricsLogo = [
        {
            icon: <VisibilityOutlined />,
            value: impression,
            comparisonValue: comparisonImpression,
            label: 'Impressions'
        },
        {
            icon: <ThumbUpOutlined />,
            value: like,
            comparisonValue: comparisonLike,
            label: 'Likes'
        },
        {
            icon: <ChatBubbleOutlineOutlined />,
            value: comment,
            comparisonValue: comparisonComment,
            label: 'Comments'
        },
        {
            icon: <CallMadeOutlined />,
            value: share,
            comparisonValue: comparisonShare,
            label: 'Shares'
        },
        {
            icon: <TouchAppOutlined />,
            value: save,
            comparisonValue: comparisonSave,
            label: 'Saves'
        },
        {
            icon: <TroubleshootOutlined />,
            value: gravity,
            comparisonValue: comparisonGravity,
            label: 'Gravity'
        }
    ]
    return (
        <Box
            display='flex'
            flexDirection='row'
            gap='25px'
        >
            {metricsLogo.map((item, index) => (
                !item.value ? null :
                <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                >
                    <Box
                        display='flex'
                        flexDirection='row'
                    >
                        {
                            showTooltip ? 
                                <Tooltip title={item.label} placement="top" >
                                    {item.icon}
                                </Tooltip>
                            :
                                item.icon
                        }
                        <Box
                            marginLeft='5px'
                        >
                            {parseAndFormatNumber(item.value, 2, true)}
                        </Box>
                
                    </Box>
                    { comparison &&
                        <Box display="flex" width={1} height={'30px'} justifyContent='center' alignItems='center' mt='1rem'>
                            <Box width={0.3} display='flex' justifyContent='center' alignItems='center'>
                                {
                                    item.comparisonValue < item.value ?
                                        <NorthIcon
                                            sx={{
                                                color: 'green'
                                            }}
                                        />
                                    : item.comparisonValue > item.value ?
                                        <SouthIcon 
                                            sx={{
                                                color: 'orange'
                                            }}
                                        />
                                    :
                                    '='
                                }
                            </Box>
                            <Box width={0.7} flexDirection="column" ml='5px' display="flex" alignContent='center' justifyContent='center'>
                                <Box height={1} display="flex" alignContent='center' > 
                                    <Typography>
                                        {parseAndFormatNumber(item.value-item.comparisonValue, 2, true)}
                                        {/* {item.comparisonValue} */}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    }
                </Box>
            ))}
        </Box>
    )
}

export default PostMetricsIcons