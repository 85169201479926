import React from 'react'
import{
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme
} from "@mui/material"

import{
    ChevronLeft,
    ChevronRightOutlined,
    HomeOutlined,
    Instagram,
    TroubleshootOutlined,
    FacebookOutlined,
    LinkedIn,
    PostAddOutlined,
    MoreHoriz
} from "@mui/icons-material"



import{ useEffect, useState } from "react";
import{useLocation, useNavigate} from "react-router-dom"
import FlexBetween from './FlexBetween';

import gravityLogo from 'assets/gravityLogo.png'
import gravityLogoBlack from 'assets/gravityLogoBlack.png'
import { checkAuth } from 'lib/auth';

const checkChannel = checkAuth().channel?.[0]?.channel;
const channel = checkChannel ? checkChannel : ['IG']



const navItems = [
    {
        text:"Dashboard",
        icon : <HomeOutlined />,
        path:"Dashboard",
        role:"user"
    },
    {
        text:"Attributes",
        icon: <TroubleshootOutlined />,
        path:"post-attributes-analysis",
        role:"user"
    },
    {
        text:"Create Post",
        icon:<PostAddOutlined />,
        path:"create-post",
        role:"user",
        org:['altair']

    },
    {
        text:"Created Post Status",
        icon: <MoreHoriz />,
        path:"created-post-status",
        role:"user",
        org:['altair']
    },
    {
        text:"All Posts",
        icon: null,
        disabled: true
    },
    ...(channel.includes("facebook") ? [{
        text:"Facebook",
        icon: <FacebookOutlined />,
        path:"posts/facebook",
        role:"user"
    }] : []),
    ...(channel.includes("IG") ? [{
        text:"Instagram",
        icon: <Instagram />,
        path:"posts/ig",
        role:"user"
    }] : []),
    ...(channel.includes("linkedin") ? [{
        text:"Linkedin",
        icon: <LinkedIn />,
        path:"posts/linkedin",
        role:"user"
    }] : []),
    {
        text:"Individual Posts",
        icon: null,
        disabled: true
    },
    ...(channel.includes("facebook") ? [{
        text:"Facebook",
        icon: <FacebookOutlined />,
        path:"individual-post/facebook",
        role:"user"
    }] : []),
    ...(channel.includes("IG") ? [{
        text:"Instagram",
        icon: <Instagram />,
        path:"individual-post/ig",
        role:"user"
    }] : []),
    ...(channel.includes("linkedin") ? [{
        text:"Linkedin",
        icon: <LinkedIn />,
        path:"individual-post/linkedin",
        role:"user"
    }] : []),
    {
        text:"Register",
        icon: null,
        path:'register',
        role:"admin"
    },
    {
        text:'Feedback',
        icon: null,
        path: 'feedback',
        role:'user'
    },
    {
        text: 'Reset Password',
        icon: null,
        path: 'reset-password-auth',
        role: 'user'
    }
]



const Sidebar = ({
    drawerWidth,
    isSidebarOpen,
    setIsSidebarOpen,
    isNonMobile
}) => {
    const { pathname } = useLocation()
    const [active, setActive] = useState("")
    const navigate = useNavigate()
    const theme = useTheme()

    const userRole = checkAuth().role
    // remove the quotes
    const org = checkAuth()?.org?.replace(/['"]+/g, '')
    console.log('org', org)
    
    useEffect(() => {
        setActive(pathname.substring(1))
    }, [pathname])

return (
    <Box component="nav">
        {isSidebarOpen && (
            <Drawer 
                open = {isSidebarOpen}
                onClose = {() => setIsSidebarOpen(false)}
                variant = "persistent"
                anchor = "left"
                sx = {{
                    width: drawerWidth,
                    "& .MuiDrawer-paper": {
                        color: theme.palette.secondary[200],
                        backgroundColor: theme.palette.background.alt,
                        boxSizing: "border-box",
                        borderWidth: isNonMobile ? 0 : "2px",
                        width: drawerWidth
                    }
                }}
            >
                <Box width="100%">
                    <Box m="1rem 1rem 1rem 2rem">
                        <FlexBetween color={theme.palette.secondary.main}>
                            <Box 
                                component="img"
                                alt="profile"
                                src={theme.palette.mode === 'dark' ? (gravityLogo) : (gravityLogoBlack)}
                                height="128px"
                                width="128px"
                                borderRadius="25%"
                                sx={{ objectFit: "cover"}}
                                onClick = {() => navigate("/dashboard")}
                            >
                                
                            </Box>
                            {!isNonMobile && (
                                <IconButton onClick={() => setIsSidebarOpen(!setIsSidebarOpen)}>
                                    <ChevronLeft />
                                </IconButton>
                            )}
                        </FlexBetween>
                    </Box>
                    <List>
                        {navItems.filter((navItem) => {
                            if (userRole === 'admin') {
                                return true; // include all items for admin
                            } else if (userRole === 'user') {
                                return navItem.role !== 'admin' && (!navItem.org || navItem.org.includes(org)); // include only user items
                            }
                            return false; // exclude any other roles
                            }).map(({ text, icon, disabled, path }) => {
                            return (
                                <ListItem key={text} disablePadding>
                                    <ListItemButton
                                        onClick={() => {
                                            if (!disabled) {
                                                const routePath = path || text.toLowerCase();
                                                navigate(`/${routePath}`);
                                                setActive(routePath);
                                            }
                                        }}
                                        
                                        sx={{
                                            backgroundColor:
                                                active === text.toLowerCase()
                                                    ? theme.palette.secondary[300]
                                                    : "transparent",
                                            color:
                                                active === text.toLowerCase()
                                                    ? theme.palette.primary[600]
                                                    : theme.palette.secondary[100],
                                            pointerEvents: disabled ? 'none' : 'auto',
                                            cursor: disabled ? 'default' : 'pointer' // Conditionally set the cursor style
                                        }}
                                    >
                                        {icon && (
                                            <ListItemIcon
                                                sx={{
                                                    ml: "1rem",
                                                    color:
                                                        active === text.toLowerCase()
                                                            ? theme.palette.primary[600]
                                                            : theme.palette.secondary[200],
                                                    pointerEvents: disabled ? 'none' : 'auto'
                                                }}
                                            >
                                                {icon}
                                            </ListItemIcon>
                                        )}
                                        <ListItemText primary={text}
                                            sx={{
                                                ml: icon ? "1%" : "3rem",
                                                color: disabled ? theme.palette.primary[200] : theme.palette.secondary[200], // Conditionally set the text color
                                            }}
                                        />
                                        {active === text.toLowerCase() && (
                                            <ChevronRightOutlined sx={{ ml: "auto" }} />
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            );
                            })}
                    </List>
                </Box>
            </Drawer>
        )}

    </Box>    
)
}

export default Sidebar