import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';
import { parseAndFormatNumber } from 'lib/helperFunction';

// Custom Legend Component
const CustomLegend = ({ data }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {data.map((item) => (
                <div key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <div style={{ width: '20px', height: '20px', borderRadius: '50%', background: item.color }}></div>
                    <span style={{ marginLeft: '10px', marginRight: '10px' }}>{item.id}</span>
                    {item.id === 'LinkedIn' && (
                        <IconButton size="small">
                            <LinkedInIcon />
                        </IconButton>
                    )}
                </div>
            ))}
        </div>
    );
};



export const ChannelBreakdownPieChart = ({ data }) => {
    return (        
        
        <ResponsivePie
            data={data}
            margin={{ top: 0, right: 80, bottom: 80, left: 120 }}
            innerRadius={0.3}
            activeOuterRadiusOffset={8}
            valueFormat={(value) => parseAndFormatNumber(value,2, true)}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            defs={[
                {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true
                },
                {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                }
            ]}
            legends={[
                {
                    anchor: 'left',
                    direction: 'column',
                    justify: false,
                    translateX:-100,
                    translateY: -20,
                    itemsSpacing: 10,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}
        >
        </ResponsivePie>
        
)
}