import React from 'react'

import {
    Box,
    Button,
    TextField,
    useTheme
} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup"

import { checkAuth } from 'lib/auth'
import { Navigate } from 'react-router-dom'

import { baseUrl } from 'config.js'

const feedbackSchema = yup.object().shape({
    feedback: yup.string().required('required'),
})

const initialValuesFeedback = {
    feedback: "",
}

const FeedbackPage = () => {

    const theme = useTheme()

    const submitFeedback = async () => {
    };
    
    const auth = checkAuth()

    const handleFormSubmit = async (values, onSubmitProps) => {
        try{
            await submitFeedback(values)
            // console.log(values, 'values')
            onSubmitProps.resetForm()
        }
        catch(error){
            console.log('error', error)
        }
    }

    return (
        <Formik
            onSubmit= {handleFormSubmit}
            initialValues = {initialValuesFeedback}
            validationSchema = {feedbackSchema}
        >
            {({
            values,
            handleBlur,
            handleChange,
            handleSubmit        }) => (
                <form onSubmit={handleSubmit}>
                    <Box 
                        display="flex" 
                        flexDirection="column" 
                        alignItems="center"
                        mt='40px' // adds 30px margin-top
                    >
                        <Box 
                            display="flex" 
                            justifyContent='center' 
                            width={1000} // TextField's width
                        >
                            <TextField
                                multiline
                                rows={15}
                                fullWidth
                                label = "Feedback"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value = {values.feedback}
                                name= "feedback"
                            />
                        </Box>
                        <Box 
                            display="flex" 
                            justifyContent='center' 
                            width={1000} // Button's width
                            mt={2} // optional: adds margin-top to separate the TextField and Button
                            backgroundColor= {theme.palette.secondary[300]}
                        >
                            <Button
                                fullWidth
                                type="submit"
                            >
                                SUBMIT FEEDBACK
                            </Button>
                        </Box>
                    </Box>
                </form>
        )}
        </Formik>
    )
}


export default FeedbackPage