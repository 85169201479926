import { Box } from '@mui/material';
import React from 'react';
import linkedinSignin from './linkedin_signin.png'

const Auth = () => {

  const generateState = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  const handleSignIn = () => {
    const clientId = '78asv3bwobk4yh';
    const redirectUri = encodeURIComponent('https://10point6labs.com/dashboard');
    const scope = 'r_organization_social';
    const state = generateState()
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}  &scope=${scope}`;

    window.location.href = authUrl;
  };

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img
        src={linkedinSignin}
        alt="Sign in with LinkedIn"
        style={{ cursor: 'pointer' }}
        onClick={handleSignIn}
      />
    </Box>
  );
};

export default Auth;
