const tokenName = 'token'

const setUsername = (username) => {
    localStorage.setItem('username', JSON.stringify(username))
}

const setUserChannel = (userChannel) => {
    localStorage.setItem('username', JSON.stringify(userChannel))
}
const setUserId = (userId) => {
    localStorage.setItem('userId', JSON.stringify(userId))
}

const setToken = (token) => {
    localStorage.setItem(tokenName, JSON.stringify(token))
}

const setRole = (role) => {
    localStorage.setItem('role', JSON.stringify(role))
}

const setOrg = (org) => {
    localStorage.setItem('org', JSON.stringify(org))
}

const setChannel = (channel) => {
    localStorage.setItem('channel', JSON.stringify(channel))
}

const checkAuth = () => {
    const token = JSON.parse(localStorage.getItem('token'));
    const role = JSON.parse(localStorage.getItem('role'))
    const username = JSON.parse(localStorage.getItem('username'))
    const org = JSON.parse(localStorage.getItem('org'))
    const userId  = JSON.parse(localStorage.getItem('userId'))
    const channel = JSON.parse(localStorage.getItem('channel'))
    return { role,token, username, org, userId, channel }
}




export  {
    setUsername,
    setUserChannel,
    setUserId,
    setToken,
    setRole,
    setOrg,
    checkAuth,
    setChannel
}