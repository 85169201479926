import React from 'react'

import {
    Box,
    Button,
    TextField,
    useTheme
} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup"

import { checkAuth } from 'lib/auth'
import {  useLocation, useNavigate } from 'react-router-dom'
import { baseUrl } from 'config'



const passwordResetSchema = yup.object().shape({
    email: yup.string().email('Invalid email').required('required'),
    newPassword: yup.string().min(6, 'Minimum 6 characters').required('required'),
});

const initialValues = {
    email: "",
    newPassword: "",
};

const PasswordResetPage = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const resetPassword = async (values, { setSubmitting, resetForm }) => {
        const response = await fetch(`${baseUrl}/auth/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        });
        
        if (response.ok) {
            const data = await response.json();
            resetForm();
            if (location.pathname === '/reset-password-unauth') {
                navigate('/');
            }
        } else {
            const errMessage = await response.text();
            console.error('Failed to reset password:', errMessage);
            setSubmitting(false); // Stop the formik from submitting
        }
    };
    
    const theme = useTheme()

    return (
        <Formik
            onSubmit={resetPassword}
            initialValues={initialValues}
            validationSchema={passwordResetSchema}
        >
        {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            resetForm
        }) => (
            <form onSubmit={handleSubmit}>
                <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(2, minmax(0,1fr))"
                    mt='40px'
                    ml='200px'
                    mr='200px'
                    justifyContent='center'
                >
                    <TextField
                        label="Email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                    />
                    <TextField
                        type="password"
                        label="New Password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.newPassword}
                        name="newPassword"
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    backgroundColor= {theme.palette.secondary[300]}
                    mt='40px'
                    ml='200px'
                    mr='200px'
                >
                    <Button
                        type="submit"
                        style = {{width:750}}
                    >
                        RESET PASSWORD
                    </Button>
                </Box>
            </form>
        )}
        </Formik>
    )
};

export default PasswordResetPage;
