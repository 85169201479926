/**
 * Calculates the time remaining until a date input
 * 
 * @param {Date} scheduledPost 
 * @returns {string} time remaining until scheduledPost
 * @ example
 * If scheduledPost is 1 day and 2 hours away, returns '1d 2h'
 * If scheduledPost is 2 hours and 1 minute away, returns '2h 1m'
 * If scheduledPost is 1 minute away, returns '1m'
 */

export const calculateTimeRemaining = (scheduledPost) => {
    // Check if scheduledPost is not a date object if parseable by Date.parse()
    if (!scheduledPost || (typeof scheduledPost === 'string' && isNaN(Date.parse(scheduledPost)))) {
        return null;
    }
    scheduledPost = new Date(scheduledPost);
    const now = new Date();
    let diff = scheduledPost.getTime() - now.getTime();

    if (diff < 0) {
        return 'Time has already passed';
    }

    // Convert milliseconds to days, hours, and minutes
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(diff / (1000 * 60));

    // Format the result
    let result = '';
    if (days > 0) {
        result += `${days}d `;
    }
    if (hours > 0 || days > 0) { // Include hours if there are days
        result += `${hours}h `;
    }
    result += `${minutes}m`;

    return result.trim();
};