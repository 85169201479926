import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { checkAuth } from 'lib/auth';
import { fetchPageAccessToken } from 'state/api';


const FacebookPost = ({ postId, width, height, pageAccessToken }) => {

    const org = checkAuth().org
    const accessTokenList = new Map ([
        ['ra','EAAGsL0zAqDABABlpuO4J95kxm0s5k22k5fvoK2rZCZAi54j12baoEYk8rLb8JD3vCicj7BbOBeIT1eeBsqzO0G3cHrOVwpgcdZBL97upA5GTirOXhMyPTDR0ZBtKqJRQYwVoR5bo23XRRV6kjOZAorDtMetXq2OVhS0g9nX7ED1EMBj6sh9pr5Qj3mvZC8MlQZD'],
        ['roh', 'EAAGsL0zAqDABAC9PffuvrBhx9XYOQhBaug4GxX0ZCoTphRCZCXu2NSWDKto2wNZBTs98X97ydWfOEMG3KdLau8tZA39m5INn48HdNusIohDttjBLcTYswTZBSXwrHpdGzxtdZBSXiu3CfuOomFd4FPQ6fHn5bjF1gWRnXCLA6dGWIYedf2p8rHhCQY5JUKpDYZD'],
        ['zg', 'EAAGsL0zAqDABAELo4VgG1D3CJ1ehxyIS8uyp4RgDGD4zDyhvDJmaFa6ZAo56ZA57deSpAh7vvAffzaq1SFZBnJGLp3RbfFnkldzD6187dfBeOfM0TQpeFOPg40izYe77PNGZCNdfjIcq4OfDIIxOjIPivBXTWpPcixXlZCqEgTZCS60dCQjzXLVKVCxpnIFiIZD'],
        ['crown', 'EAAGsL0zAqDABAG5eitzuvc9tw58lKCH3iawz0ezZAMIKZBnd5yzfAFlhZBx2NfEEYwUPYv3fHjwvaMFKJg9tBdLgFZCbVHNURDcCphtKmqNgvZBmRzUnjorTD9F1fpHu6GBjEFfMiSIsSJmBPJZAZC5clc6ZBAZAx2Fa3jpCaqUXB6DHIwAB9McNqNKzDMZAgTAZBQZD'],
        ['witness', 'EAAGsL0zAqDABO5ZA02WepdgVRUAUVzTR6q0bySi1d3YiG4IwVwLeA4yy9AuqWuTCXFSPQD42m4ZBZBlfnRzSTd37rVJgXlqbrZCLy46gIuvSSCZB1ubx6PAQBgVi2azkv3Feod1k3Rf2eIthLTABvohOz6UBOfV84apPqbGme4eA7JQNZBn9AAdTbi33eSVtSTh7fgZCsUZD']
    ])
    
    const [post, setPost] = useState(null);

    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        if (!pageAccessToken) {
            setAccessToken(accessTokenList.get(org))
        } else {
            setAccessToken(pageAccessToken)
        }
    })
    , [org, pageAccessToken]

    useEffect(() => {
        if (accessToken) {
            const fetchPost = async () => {
            try {
                const response = await axios.get(`https://graph.facebook.com/v11.0/${postId}?fields=message,permalink_url,full_picture&access_token=${accessToken}`);
                setPost(response.data);
            } catch (error) {
                console.error('Error fetching Facebook post:', error);
            }
            };

            fetchPost();
        }
    }, [postId, accessToken]); //eslint-disable-line

    if (!post || !accessToken) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ width: `${width}px`, height: `${height}px`, overflow: 'auto' }}>
            <a href={post.permalink_url} target="_blank" rel="noreferrer">
                <img src={post.full_picture} alt="Post" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
            </a>
            <p>{post.message}</p>
        </div>
    );

};

export default FacebookPost;
