import React from 'react'
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { themeSettings } from "theme";
import { useMemo } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';

import { checkAuth } from 'lib/auth';

import Layout from "scenes/layout"
import Posts from "scenes/posts"
import Dashboard from "scenes/dashboard"
import IndividualPost from "scenes/individualPost"
import Gravity from "scenes/gravity";
import RegisterPage from "scenes/registerPage";
import LoginPage from "scenes/loginPage";
import Comments from 'scenes/comments';
import Auth from 'scenes/auth';
import FeedbackPage from 'scenes/feedbackPage';
import PasswordResetPage from 'scenes/resetPassword';
import PostAttributesAnalysis from 'scenes/postAttributesAnalysis';
import CreatePost from 'scenes/createPost';
import CreatedPostStatus from 'scenes/createdPostStatus';


function App() {
const mode = useSelector((state)=> state.global.mode)
const theme = useMemo(()=> createTheme(themeSettings(mode)), [mode])

// Disable console.log in production
if (process.env.NODE_ENV === 'production') {
    console.log = function() {};
    }

const withAuth = (Component) => {
    const AuthRoute = (props) => {
    const auth = checkAuth();
    const isAuthenticated = auth && auth.token;

    return isAuthenticated ? (
        <Component {...props} />
    ) : (
        <Navigate to="/" replace={true} />
    );
    };

    return AuthRoute;
};

const ProtectedDashboard = withAuth(Dashboard);
const ProtectedGravity = withAuth(Gravity);
const ProtectedComments = withAuth(Comments);
const ProtectedIndividualPost = withAuth(IndividualPost);
const ProtectedPosts = withAuth(Posts);
const ProtectedAuth = withAuth(Auth);
const ProtectedFeedback = withAuth(FeedbackPage)
const ProtectedPasswordResetPage = withAuth(PasswordResetPage);
const ProtectedCreatePost = withAuth(CreatePost)
const ProtectedCreatedPostStatus = withAuth(CreatedPostStatus)


return (
    <div className="app">
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Routes>
                    <Route path="/" element={<LoginPage/>}/>
                    <Route path='/reset-password-unauth' element={<PasswordResetPage/>}/>
                    <Route element={<Layout />}>
                        <Route path="/dashboard" element={ <ProtectedDashboard/> } />
                        <Route path = "/gravity" element={ <ProtectedGravity/> } />
                        <Route path = "/individual-post/:channel" element={ <ProtectedIndividualPost/> } />
                        <Route path = "/comments" element={ <ProtectedComments/> } />
                        <Route path = "/posts/:channel"  element={ <ProtectedPosts/> } />
                        <Route path = "/auth" element={ <ProtectedAuth/> } />
                        <Route path ="/feedback" element={ <ProtectedFeedback/>} />
                        <Route path ="/reset-password-auth" element={ <ProtectedPasswordResetPage/>} />
                        <Route path='/post-attributes-analysis' element={<PostAttributesAnalysis/>} />
                        <Route path='/create-post' element={<ProtectedCreatePost/>} />
                        <Route path='/created-post-status' element={<ProtectedCreatedPostStatus/>} />
                        {/* ADMIN ROUTES */}
                        <Route path = "/register" element={ <RegisterPage/>} /> 
                    </Route>

                </Routes>
            </ThemeProvider>
    
        </BrowserRouter>
    </div>
);
}

export default App;