import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const ChannelLogo = ({channel}) => {

    const getChannelLogo = (channel) => {
        switch (channel) {
            case 'linkedin':
                return <LinkedInIcon />
            case 'facebook':
                return <FacebookIcon />
            case 'instagram':
            case 'IG':
                return <InstagramIcon />
            default:
                return null
        }
    }

    return (
        <div>
            {getChannelLogo(channel)}
        </div>
    )
}

export default ChannelLogo