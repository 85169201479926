/* eslint-disable react/react-in-jsx-scope */
import { ResponsiveLine } from "@nivo/line"


const LineChart = ({data}) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 20, right: 2, bottom: 15, left: 5 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat="d"
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        isInteractive={true}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        enableArea={true}
        legends={[
        ]}
    />
)

export default LineChart