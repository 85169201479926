import React, { useEffect, useState } from 'react'
import { Alert, Autocomplete, Backdrop, Box, Button, CircularProgress, FormControlLabel, FormGroup, IconButton, Radio, Snackbar, TextField, Typography } from '@mui/material'
import Header from 'components/Header'
import { AddCircleOutline, CheckBox, Delete, Facebook, Instagram, KeyboardArrowDown, KeyboardArrowUp, LinkedIn } from '@mui/icons-material'
import AWS from 'aws-sdk'
import { v4 as uuidv4, v4 } from 'uuid';
import { checkAuth } from 'lib/auth'
import { controllerCreatedPost, createPost, createPostPendingRequest } from 'state/api'

const CreatePost = () => {

    const auth = checkAuth()
    const org = auth ? auth.org :undefined

    const channelOptions = [
        {
            icon: <Facebook />,
            value: 'facebook'
        },
        {
            icon: <Instagram />,
            value: 'IG'
        },
        {
            icon: <LinkedIn />,
            value: 'linkedin'
        }
    ]
    console.log('auth channel', auth.channel[0].channel)

    const [postName, setPostName] = useState('')

    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {

        if (selectedFiles.length + event.target.files.length > 7) {
            alert('Max 7 files per post.')
            return
        }
        // check file size
        if (event.target.files[0].size > 30000000) {
            alert('File size is too big. Max 30MB per file.')
            return
        }

        const files = Array.from(event.target.files);
    
        Promise.all(files.map(file => {
            if (file.type.startsWith('video')) {
                return Promise.resolve({ file, url: URL.createObjectURL(file) });
            }
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const targetAspectRatio = 1.91; // for example, 1.91:1
                    let newWidth, newHeight;
    
                    // Calculate new dimensions
                    if (img.width / img.height > targetAspectRatio) {
                        // Width is the limiting dimension
                        newWidth = img.height * targetAspectRatio;
                        newHeight = img.height;
                    } else {
                        // Height is the limiting dimension
                        newWidth = img.width;
                        newHeight = img.width / targetAspectRatio;
                    }
    
                    // Set canvas dimensions and draw resized image
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    
                    // Convert canvas to data URL
                    canvas.toBlob(blob => {
                        const resizedFile = new File([blob], file.name, { type: file.type, lastModified: Date.now() });
                        resolve({ file: resizedFile, url: URL.createObjectURL(resizedFile) });
                    }, file.type);
                };
                img.onerror = reject;
                img.src = URL.createObjectURL(file);
            });
        }))
        .then(filesWithUrls => {
            console.log('Resized filesWithUrls', filesWithUrls);
            setSelectedFiles(prevFiles => [...prevFiles, ...filesWithUrls]);
        })
        .catch(error => {
            // Handle the error
            console.error(error);
        });
    };
    
    const handleClick = () => {
        document.getElementById('file-input').click();
    };
    
    const renderMedia = () => {
        return selectedFiles.map((media, index) => {
            const fileType = media.file.type;
            const fileUrl = media.url;

            return (
                <Box key={index} pb='2rem' pt='2rem' style={{ maxWidth: '100%', maxHeight: '400px', borderBottom:'3px solid #ccc' }}>
                    <Box
                        id='media-controller-box'
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                        alignItems='center'
                    >
                        <IconButton
                            onClick={() => {
                                setSelectedFiles(selectedFiles.filter((item, i) => i !== index))
                            }}
                        >
                            <Delete style={{fontSize:25}}/>
                        </IconButton>
                        { index !== 0 &&
                            <IconButton
                                onClick={() => {
                                    if (index === 0) return;
                                    const temp = selectedFiles[index];
                                    selectedFiles[index] = selectedFiles[index - 1];
                                    selectedFiles[index - 1] = temp;
                                    setSelectedFiles([...selectedFiles]);
                                }}
                            >
                                <KeyboardArrowUp style={{fontSize:25}}/>
                            </IconButton>
                        }
                        { index !== selectedFiles.length - 1 &&
                            <IconButton
                                onClick={() => {
                                    if (index === selectedFiles.length - 1) return;
                                    const temp = selectedFiles[index];
                                    selectedFiles[index] = selectedFiles[index + 1];
                                    selectedFiles[index + 1] = temp;
                                    setSelectedFiles([...selectedFiles]);
                                }}
                            >
                                <KeyboardArrowDown style={{fontSize:25}}/>
                            </IconButton>
                        }
                        {
                            fileType.startsWith('video') && imageOrVideo === 'video' && chooseToUploadImageOrVideoMode && selectedFiles.findIndex(file => file.file.type.startsWith('video')) === index &&
                            // check if its the first video
                            <Typography>
                                This video will be uploaded for Facebook and Linkedin
                            </Typography>
                        }
                    </Box>
                    {fileType.startsWith('image') && <img src={fileUrl} alt={`Uploaded ${index}`} style={{ maxWidth: '100%', maxHeight: '400px' }} />}
                    {fileType.startsWith('video') && <video src={fileUrl} controls style={{ maxWidth: '100%', maxHeight: '400px' }} />}
                </Box>
            );
        });
    };

    AWS.config.update({
        region: 'eu-west-2', 
        accessKeyId: 'AKIASN3EIREWNCRWAKWS',
        secretAccessKey: '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'
    });
    
    const s3 = new AWS.S3();

    // maket his a async function
    const uploadFileToS3 = async (file) => {
        const fileName = v4();
        const params = {
            Bucket: 'gravity-image-hosting',
            Key: fileName,
            Body: file,
            ContentType: file.type,
            ACL: 'public-read' // or another ACL according to your needs
        };
    
        const options = {partSize: 20 * 1024 * 1024, queueSize: 1};
        try {
            console.log('params', params)
            const data = await s3.upload(params, options).promise();
            console.log('data from s3 upload', data)
            console.log("Successfully uploaded file to S3:", data.Location);

            if (!data.Location){
                // smth is wrong delete all the key from s3 and throw an error message
                const params = {
                    Bucket: 'gravity-image-hosting',
                    Key: fileName,
                };
                s3.deleteObject(params, fileName)
            }
    
            let fileType = file.type;
            if (fileType.startsWith('image')) {
                fileType = 'image';
            } else if (fileType.startsWith('video')) {
                fileType = 'video';
            }
            return { url: data.Location, type: fileType };
        } catch (err) {
            console.error("Error uploading to S3:", err);
            throw err; // Rethrowing the error is important to handle it later
        }
    };

    // this function is to parse which posts is for which channel
    const linkPostToChannel =  () => {
        console.log('selectedChannel', selectedChannel)
        if (igButtonDisabled && selectedChannel.includes('IG')) {
            console.log('selectedChannel in  linkpost1', selectedChannel)
            setSelectedChannel(selectedChannel.filter(channel => channel !== 'IG'))
        }
        console.log('selectedChannel in  linkpost2', selectedChannel)
        if (!chooseToUploadImageOrVideoMode) { // default mode, every channel would have all the selected files
            return selectedChannel.map(channel => {
                return {
                    channel: channel,
                    files: selectedFiles
                }
            })
        } else { // if chooseToUploadImageOrVideoMode is true, then we need to check if the file is image or video
            return selectedChannel.map(channel => {
                if (channel === 'facebook' || channel === 'linkedin') {
                    if (imageOrVideo === 'image') {
                        return {
                            channel: channel,
                            files: selectedFiles.filter(file => file.file.type.startsWith('image'))
                        }
                    } else {
                        return {
                            channel: channel,
                            // only the first video will be uploaded
                            files: [selectedFiles.find(file => file.file.type.startsWith('video'))] 
                        }
                    }
                } else {
                    return {
                        channel: channel,
                        files: selectedFiles
                    }
                }})}
    }
    
    const [successUploadInfoAlertOpen, setSuccessUploadInfoAlertOpen] = useState(false)
    const successUploadInfoMessage = <span> Upload initiated. Please go to the <a href="/created-post" style={{ textDecoration: 'underline' }}>Created Post</a> page to check the status of your upload.</span> 
    const handleSuccessUploadInfoAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessUploadInfoAlertOpen(false);
    };

    const handleUploadButtonClick = async () => {
        setLoadingBackdropOpen(true)
        const uploadResults = [];
    
        const linkPostToChannelResults = linkPostToChannel();
        console.log('linkPostToChannelResults', linkPostToChannelResults)
        // Outer loop for each channel
        for (const channel of linkPostToChannelResults) {
            try{
                const channelUploadPromises = channel.files.map(file => {
                    return uploadFileToS3(file.file, channel.channel);
                });
        
                // Await all uploads for this channel
                try {
                    const fileNames = await Promise.all(channelUploadPromises);
                    uploadResults.push({ channel, fileNames });
                } catch (err) {
                    console.error(`Error uploading files for channel ${channel}:`, err);
                }
            } catch (err) {
                console.error(`Error uploading files for channel ${channel}:`, err);
                return;
            }
        }
    
        console.log('Upload results:', uploadResults);

        const pendingRequestToCreateChannelAndFiles = uploadResults.map(({ channel, fileNames }) => {
            return {
                channel: channel.channel,
                files: fileNames,
                status: 'Pending',
            }
        })

        let parsedScheduledPostTime = null;

        if (scheduledPostTime) {
            const dateTime = new Date(scheduledPostTime);
            dateTime.setHours(scheduledPostHour, scheduledPostMinute, 0, 0); // Set hours, minutes, seconds, milliseconds
            parsedScheduledPostTime = dateTime;
        }

        if (parsedScheduledPostTime && parsedScheduledPostTime <= new Date()) { // just in case if the user afk
            alert('Scheduled post time must be in the future.');
            return;
        }

        console.log('parsedScheduledPostTime', parsedScheduledPostTime)

        const pendingRequestToCreatePost = {
            createdPostName: postName,
            caption: caption,
            org: org,
            scheduledPost: parsedScheduledPostTime,
            createdAt: new Date(),
            lastUpdated: new Date(),
            channel: pendingRequestToCreateChannelAndFiles,
        }


        
        try {
            const res = await controllerCreatedPost({ action: 'insert', data: pendingRequestToCreatePost });
            console.log('res', res);

            if (res.status === 207) {
                const overwriteSavedPost = confirm('A post with the same name already exists, do you want to overwrite it?');
                console.log('overwriteSavedPost', overwriteSavedPost);

                if (overwriteSavedPost) {
                    const updateRes = await controllerCreatedPost({ action: 'update', data: pendingRequestToCreatePost });
                    if (updateRes.status !== 200) {
                        alert("Unexpected error, please contact support");
                    }
                } else {
                    // If user clicks 'Cancel', exit the function here.
                    return;
                }
            }
        } catch (err) {
            console.log('err', err);
            alert("Unexpected error, please contact support");
            return; // Exit the function in case of an error
        }

        console.log('pendingRequestToCreatePost', pendingRequestToCreatePost)
    
        // Creating posts with the respective media URLs for each channel
        let postCreationPromises
        if (!scheduledPostMode) {
            postCreationPromises =  uploadResults.map(({ channel, fileNames }) => {
                const dataToUpload = {
                    createdPostName: postName,
                    caption: caption,
                    mediaUrl: fileNames,
                    org: org,
                    channel: channel.channel,
                    scheduledPost: parsedScheduledPostTime
                };
                console.log('dataToUpload', dataToUpload)
                return createPost(dataToUpload);
            });
        }
    
        // Await all post creation promises
        console.log('data sent to create post', postCreationPromises)

        setSuccessUploadInfoAlertOpen(true)
        setLoadingBackdropOpen(false)
    };
    
    
    
    const [caption, setCaption] = useState('')
    const [scheduledPostMode, setScheduledPostMode] = useState(false)
    const [scheduledPostTime, setScheduledPostTime] = useState('')
    const [scheduledPostHour, setScheduledPostHour] = useState('')
    const [scheduledPostMinute, setScheduledPostMinute] = useState('')
    useEffect(() => {
        const now = new Date();
        const currentHour = now.getHours();
        const currentMinute = now.getMinutes();
        const currentDay = now.getDate();
    
        // Check if the selected hour is in the past
        if (scheduledPostTime && new Date(scheduledPostTime) <= now) {
            setScheduledPostTime(now.toISOString().slice(0, 10));
            console.log(' now date', now.toISOString().slice(0, 10))

            if (scheduledPostHour < currentHour) {
                console.log('hour is in the past')
                setScheduledPostHour(currentHour);
                setScheduledPostMinute(0); // Reset minute to 0 if hour is in the past
            } else if (scheduledPostHour === currentHour) {
                // Check if the selected minute is in the past within the same hour
                if (scheduledPostMinute < currentMinute) {
                    console.log('minute is in the past')
                    const nextInterval = Math.ceil(currentMinute / 15) * 15;
                    setScheduledPostMinute(nextInterval);
                }
            }
        }
    }, [scheduledPostHour, scheduledPostMinute, scheduledPostTime]);
    
    
    const [selectedChannel, setSelectedChannel] = useState(auth.channel[0].channel)
    console.log('selectedChannel', selectedChannel)
    const handleChannelChange = (channel) => {
        console.log('channel', channel)
        if (selectedChannel.includes(channel)) {
            setSelectedChannel(selectedChannel.filter(item => item !== channel))
        } else {
            setSelectedChannel([...selectedChannel, channel])
        }
    }
    
    // when there are images and videos, only instagram is available, so if other channels are selected
    // we need to ask the user to choose to upload image or video
    const [chooseToUploadImageOrVideoMode, setChooseToUploadImageOrVideoMode] = useState(false)
    useEffect(() => {
        if (selectedChannel.includes('facebook') || selectedChannel.includes('linkedin')) {
            // check in the selected files if there are both images and videos
            let hasImage = false;
            let hasVideo = false;
            selectedFiles.forEach(file => {
                if (file.file.type.startsWith('image')) {
                    hasImage = true;
                } else if (file.file.type.startsWith('video')) {
                    hasVideo = true;
                }
            });
            if (hasImage && hasVideo) {
                setChooseToUploadImageOrVideoMode(true);
            } else {
                setChooseToUploadImageOrVideoMode(false);
            }
        } else {
            setChooseToUploadImageOrVideoMode(false);
        }
    }
    , [selectedChannel, selectedFiles])
    const [imageOrVideo, setImageOrVideo] = useState('image')

    const [createPostButtonDisabled, setCreatePostButtonDisabled] = useState(true)
    useEffect(() => {
        if (scheduledPostMode) {
            if (postName && caption && selectedChannel.length > 0 && scheduledPostTime && scheduledPostHour && scheduledPostMinute) {
                setCreatePostButtonDisabled(false)
            } else {
                setCreatePostButtonDisabled(true)
            }
            return;
        }
        if (postName && caption && selectedChannel.length > 0 ) {
            setCreatePostButtonDisabled(false)
        } else {
            setCreatePostButtonDisabled(true)
        }
    }, [postName, caption, selectedChannel, selectedFiles, scheduledPostMode, scheduledPostTime, scheduledPostHour, scheduledPostMinute])

    const [igButtonDisabled, setIgButtonDisabled] = useState(false)
    useEffect(() => {
        if (!auth.channel[0].channel.includes('IG')) return;
        // for instagram, a media is required
        if (selectedChannel.includes('IG') && selectedFiles.length === 0) {
            setIgButtonDisabled(true)
        } else {
            setIgButtonDisabled(false)
        }
    }, [selectedChannel, selectedFiles])

    const [loadingBackdropOpen, setLoadingBackdropOpen] = useState(false)


    return (
        <Box
            id='main-box'
            pr='5rem'
            pl='5rem'
            pt='1.5rem'
            pb='3rem'
            display='flex'
            flexDirection='column'
            minHeight= "80vh"
            width='100%'
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingBackdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Header title='Create Post' />
            <Box
                gridTemplateColumns='1fr 1fr'
                display='grid'
                mt='1rem'
                width='100%'
                height='100%'
                minHeight='80vh'
            >
                <Box
                    display='flex'
                    // bgcolor='violet'
                    height='100%'
                    width='100%'
                    pr='2rem'
                    borderRight='5px solid #ccc'
                    flexDirection='column'
                >
                    <TextField
                        id="outlined-multiline-static"
                        label="Title"
                        multiline
                        fullWidth
                        variant="standard"
                        inputProps={{ maxLength: 100 }}
                        onChange={(e) => setPostName(e.target.value)}
                        value={postName}
                    />
                    <Typography
                        id='word-count'
                        sx={{
                            textAlign: 'right',
                            mb:'1rem'
                        }}
                    >
                        {postName.length}/100
                    </Typography>
                    <TextField
                        id="outlined-multiline-static"
                        label="Caption"
                        multiline
                        rows={10}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setCaption(e.target.value)}
                        value={caption}
                    />
                    <Typography
                        id='word-count'
                        sx={{
                            mt: '0.5rem',
                            textAlign: 'right',
                        }}
                    >
                        {caption.length}/2000
                    </Typography>
                    <Box
                        display='flex'
                        flexDirection='row'
                        mt='1rem'
                        mb='1rem'
                    >
                        {/* map the icon button based on auth.channel */}
                        {
                            channelOptions.map((option, index) => {
                                if (auth.channel[0].channel.includes(option.value)) {
                                    return (
                                        <IconButton
                                            key={index}
                                            onClick={() => handleChannelChange(option.value)}
                                            disabled = {option.value === 'IG' && igButtonDisabled}
                                        >
                                            {React.cloneElement(option.icon, { style: 
                                                { fontSize: 30, opacity: selectedChannel.includes(option.value) ? 1 : 0.1 } 
                                            })}
                                        </IconButton>
                                    )
                                }
                                return null; // Return null or some other element for the cases where the condition is not met
                            })
                        }
                    </Box>
                    {
                        chooseToUploadImageOrVideoMode &&
                        <Box
                            display='flex'
                            flexDirection='column'
                        >
                            <FormGroup>
                                <FormControlLabel 
                                    control={<Radio />}
                                    label='Only upload image'
                                    checked={imageOrVideo === 'image'}
                                    onClick={() => setImageOrVideo('image')}
                                />
                                <FormControlLabel
                                    control={<Radio />}
                                    label='Only upload video'
                                    checked={imageOrVideo === 'video'}
                                    onClick={() => setImageOrVideo('video')}
                                />
                            </FormGroup>
                            <Box
                                display='flex'
                                flexDirection='column'
                                gap='1.5rem'
                            >
                                <Typography>
                                    Only image or video can be uploaded to Linkedin and Facebook. 
                                </Typography>
                                <Typography>
                                    If video is selected, only the first video will be uploaded. If image is selected, all images will be uploaded.
                                </Typography>
                                <Typography>
                                    If Instagram is selected, all selected images and videos will be uploaded.
                                </Typography>
                            </Box>
                        </Box>
                    }
                    <Button
                        variant='contained'
                        color='secondary'
                        sx={{
                            mt: '1rem',
                            height: '2rem',
                            width: '10rem',
                            opacity: scheduledPostMode ? 1 : 0.5
                        }}
                        onClick={() => setScheduledPostMode(!scheduledPostMode)}
                    >
                        Schedule Post
                    </Button>
                    {
                        scheduledPostMode &&
                        <Box
                            display='flex'
                            flexDirection='row'
                            alignItems='center'
                            gap='1rem'
                            mt='1rem'
                        >
                            <TextField
                                id="datetime-local"
                                label="Schedule Post"
                                type="date"
                                sx={{
                                    mt: '1rem',
                                }}
                                // defaultValue="2017-05-24T10:30"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setScheduledPostTime(e.target.value)}
                                value={scheduledPostTime}
                                // key={scheduledPostTime}
                            />
                            <Autocomplete
                                options={[...Array(24).keys()]}
                                renderInput={(params) => <TextField {...params} label="Hour" />}
                                sx={{
                                    mt: '1rem',
                                }}
                                onChange={(e, value) => setScheduledPostHour(value)}
                                value={scheduledPostHour}
                                // key={scheduledPostHour}
                            />
                            <Autocomplete
                                options={[0,15,30,45]}
                                renderInput={(params) => <TextField {...params} label="Minute" />}
                                sx={{
                                    mt: '1rem',
                                }}
                                onChange={(e, value) => setScheduledPostMinute(value)}
                                defaultValue={0}
                                value={scheduledPostMinute}
                                // key={scheduledPostMinute}
                            />
                        </Box>
                    }
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={handleUploadButtonClick}
                        sx={{
                            mt: '5rem',
                            height: '2rem',
                            width: '5rem',
                            alignSelf:'center',
                            justifySelf:'center'
                        }}
                        disabled={createPostButtonDisabled}
                    >
                        Post
                    </Button>
                </Box>
                <Box
                    display='flex'
                    width='100%'
                    pl='2rem'
                    justifyContent='center'
                >
                    <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                    >
                        <input
                            type="file"
                            id="file-input"
                            multiple
                            style={{ display: 'none' }}
                            accept="image/*,video/*"
                            onChange={handleFileChange}
                        />
                        <IconButton
                            aria-label='add'
                            sx={{ fontSize: 50 }}
                            onClick={handleClick}
                            disabled={selectedFiles.length >= 7}
                            opacity={selectedFiles.length >= 7 ? 0.1 : 1}
                        >
                            <AddCircleOutline sx={{ fontSize: 50 }} />
                        </IconButton>
                        <Typography>
                            Add Media
                        </Typography>
                        <Typography>
                            Max 7 files
                        </Typography>
                        <Typography>
                            Max 30MB per file
                        </Typography>
                        <Typography>
                            Please contact support if you need to upload more files, we might be able to help!
                        </Typography>
                        {selectedFiles && renderMedia()}
                    </Box>
                </Box>
            </Box>
            <Snackbar open={successUploadInfoAlertOpen} autoHideDuration={6000} onClose={handleSuccessUploadInfoAlertClose}>
                <Alert onClose={handleSuccessUploadInfoAlertClose} severity='info' sx={{ width: '100%' }}>
                    {successUploadInfoMessage}
                </Alert>
            </Snackbar>
        </Box> 
        
    )
}

export default CreatePost