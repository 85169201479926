import React, {useEffect, useState} from 'react'
import FlexBetween from 'components/FlexBetween'
import { useTheme } from '@emotion/react'
import { Autocomplete, Typography, useMediaQuery, TextField, Button } from '@mui/material'
import { Box,  List, ListItem, ListItemText, ListItemAvatar, Skeleton } from '@mui/material'
import StatBox from 'components/StatBox'
import { TroubleshootOutlined, 
ThumbUpOutlined,
CallMadeOutlined,
TouchAppOutlined,
ChatBubbleOutlineOutlined,
FacebookOutlined,
Instagram,
LinkedIn
} from '@mui/icons-material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FacebookPost from 'components/FacebookPost'

import { getBestPostByChannel, getDailyDashboardData, getIntervalDailyDashboardData, getTodayDashboardData, sendLinkedinPageToken } from 'state/api'

import { checkAuth } from 'lib/auth'
import InstagramPost from 'components/InstagramPost'
import LinkedInPost from 'components/LinkedinPost'
import DatePicker from "react-datepicker";
import '../../App.css'
import "react-datepicker/dist/react-datepicker.css";

const Dashboard = () => {

    // const notAdmin = <Navigate to ='/dashboard'/> 
    // const unauth = <Navigate to = '/'/>

    const auth = checkAuth()
    const role = auth ? auth.role : undefined
    // const token = auth ? auth.token : unauth
    const org = auth ? auth.org : undefined
    const userId = auth ? auth.userId : undefined

    // admin ability to select different org

    const orgOptions = ['roh', 'ra','zg', 'crown', 'witness','altair']
    const [orgInputValue, setOrgInputValue] = useState(org)

    const setOrgValue = (newInputValue) => {
        if (newInputValue === 'zg' || newInputValue === 'altair'){
            localStorage.setItem('channel', JSON.stringify([{"channel":["facebook","IG","linkedin"]}]))
        } 
        else if (newInputValue === 'crown') {
        localStorage.setItem('channel', JSON.stringify([{"channel":["facebook",'linkedin']}]))
        } else if (newInputValue === 'witness') {
        localStorage.setItem('channel', JSON.stringify([{"channel":["facebook", "IG"]}]))
        }
        else {
        localStorage.setItem('channel',JSON.stringify([{"channel":["facebook","IG"]}]))
        }
        setOrgInputValue(newInputValue)
        localStorage.setItem('org', JSON.stringify(newInputValue))
    }

    const theme = useTheme()
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)")

    // for fechting linkedin api token
    useEffect(() => {
        const url = window.location.href
        const accessTokenRegex = /code=([^&]*)/
        const accessToken = url.match(accessTokenRegex)

        if (accessToken) {
        sendLinkedinPageToken({userId: userId,accessToken:accessToken[1]})
        }

    }, []) //eslint-disable-line


    // options for the select a period dropdowns
    const options =['Today', "Last 3 Days", "Last 7 Days",'All Time', 'Custom Range']

    // default dropdown period value is Today
    const [inputValue, setInputValue] = useState(options[0])

    // these are the data for each of the periods
    const [todayDashboardData, setTodayDashboardData] = useState([]); // total data for today
    const [lastSevenDaysData, setLastSevenDaysData] = useState({
                                                        comment: 0,
                                                        gravity: 0,
                                                        impression: 0,
                                                        like: 0,
                                                        share: 0
                                                        })
    const [lastThreeDaysData, setLastThreeDaysData] = useState({
                                                        comment: 0,
                                                        gravity: 0,
                                                        impression: 0,
                                                        like: 0,
                                                        share: 0
                                                        })
    const [allTimeData, setAllTimeData] = useState({
                                            comment: 0,
                                            gravity: 0,
                                            impression: 0,
                                            like: 0,
                                            share: 0
                                            })
    const [customRangeData, setCustomRangeData] = useState({
                                            comment: 0,
                                            gravity: 0,
                                            impression: 0,
                                            like: 0,
                                            share: 0
                                            })


    const [metricsBreakdownData, setMetricsBreakdownData] = useState({})
    // while these are for the graphs data, same concepts with the previous ones
    const [todayGraphData, setTodayGraphData] = useState([])
    const [allTimeGraphData, setAllTimeGraphData] = useState([])
    const [lastThreeDaysGraphData, setLastThreeDaysGraphData] = useState([])
    const [lastSevenDaysGraphData, setLastSevenDaysGraphData] = useState([])
    const [customRangeGraphData, setCustomRangeGraphData] = useState([])
                                            
    const [isLoading, setIsLoading] = useState(true);

    const userName = checkAuth().username

    // custom functions for filtering date range
    const isWithinRange = (date, start, end) => (
        date >= start && date <= end
    )

    // very useful for setting day range
    const nDaysAgo = (n) => {
        const date = new Date()
        date.setHours(0, 0, 0, 0)
        date.setDate(date.getDate() - n)
        return date
    }

    // mainly for getting the aggregate filtered date range data
    const metricsInRange = (data, start, end) => (
        data.reduce((acc, item) => {
        const itemDate = new Date(item._id)
        itemDate.setHours(0, 0, 0, 0)
        if (isWithinRange(itemDate, start, end)){
            acc.comment += item.comment
            acc.gravity += item.gravity
            acc.impression += item.impression
            acc.like += item.like
            acc.share += item.share
        }
        return acc
        },{
            comment:0,
            gravity:0,
            impression:0,
            like:0,
            share:0
        })
        )

    // this is to add the today dashboard data as it lives in a seperate collection and views
    // compared to the other periods
    const addMetrics = (metric1, metric2) => ({
        comment: metric1.comment + metric2.comment,
        gravity: metric1.gravity + metric2.gravity,
        impression: metric1.impression + metric2.impression,
        like: metric1.like + metric2.like,
        share: metric1.share + metric2.share
    })

    function sumUpData(dataArray) {
        if (!dataArray || dataArray.length === 0) {
        return {
            // channel: "All",
            comment: 0,
            gravity: 0,
            impression: 0,
            like: 0,
            share: 0
        };
    }
        let summedData = {
            // channel: "All",
            comment: 0,
            gravity: 0,
            impression: 0,
            like: 0,
            share: 0
        };

        dataArray.forEach(data => {
            summedData.comment += data.comment;
            summedData.gravity += data.gravity;
            summedData.impression += data.impression;
            summedData.like += data.like;
            summedData.share += data.share;
        });

        return [summedData];
    }

    const [selectedChannel, setSelectedChannel] = useState(checkAuth().channel[0].channel)
    const [dataUpdated, setDataUpdated] = useState(false)
    const [bestPost, setBestPost] = useState(null)
    const [selectedBestPostChannel, setSelectedBestPostChannel] = useState(selectedChannel[0])
    const [selectedBestPost, setSelectedBestPost] = useState(null)

    // this useEffect is for fetching the data and updating the previous useStates
    useEffect(() => {
        setDataUpdated(false);
        Promise.all([getTodayDashboardData(org, selectedChannel),getIntervalDailyDashboardData(org, selectedChannel), getDailyDashboardData(org, selectedChannel), getBestPostByChannel(org, selectedChannel)])
        .then(([todayDashboardDataResponse, intervalDailyDashboardDataResponse, dailyDashboardDataResponse, bestPostResponse]) => {
            setBestPost(bestPostResponse.data)
            const bestPostForSelectedChannel = bestPostResponse.data.find(post => post.channel === selectedBestPostChannel);
            setSelectedBestPost(bestPostForSelectedChannel || {});
            setMetricsBreakdownData(todayDashboardDataResponse.data)
            setTodayDashboardData(todayDashboardDataResponse.data)
            // setTodayDashboardData(sumUpData(todayDashboardDataResponse.data));

            // last three days aggregate data
            const twoDays = nDaysAgo(2)
            const oneDay = nDaysAgo(1)
            const twoDaysAgoData = metricsInRange(dailyDashboardDataResponse.data, twoDays, oneDay)
            const threeDaysAgoData = addMetrics(twoDaysAgoData, todayDashboardDataResponse.data[0])
            setLastThreeDaysData(threeDaysAgoData)

            // last seven days aggregate data
            const sevenDays = nDaysAgo(7)
            const sixDaysAgoData = metricsInRange(dailyDashboardDataResponse.data, sevenDays, oneDay)
            const sevenDaysAgoData = addMetrics(sixDaysAgoData, todayDashboardDataResponse.data[0])
            setLastSevenDaysData(sevenDaysAgoData)

            // all time aggregate data
            const allTimeData = addMetrics(dailyDashboardDataResponse.data.reduce((acc, item) => {
                acc.comment += item.comment
                acc.gravity += item.gravity
                acc.impression += item.impression
                acc.like += item.like
                acc.share += item.share
                return acc
            },{
                comment:0, 
                gravity:0,
                impression:0,
                like:0,
                share:0
            }), todayDashboardDataResponse.data[0])
            setAllTimeData(allTimeData)
            setCustomRangeData(allTimeData)
            
            // this section is for chart data
            // this today is for the todayDashboardData, which doesnt have a date variable
            let today = new Date()
            const todayISO = today.toISOString()
            const todayDate = new Date(todayISO);
            const formattedTodayDate = todayDate.toLocaleDateString("en-US", {year: 'numeric', month: '2-digit', day: '2-digit'}) 
                                    + " " 
                                    + todayDate.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'});

            // function to create today and all time charts
            const createAllTimeOrTodayChartData = (data, metric) => {
            const lineData = data.map((entry) => {
                const date = new Date(entry.timestamp ? entry.timestamp : entry._id);
                const formattedDate = date.toLocaleDateString("en-US", {year: 'numeric', month: '2-digit', day: '2-digit'}) 
                                    + " " 
                                    + date.toLocaleTimeString("en-US", {hour: '2-digit', minute: '2-digit'});
                return {x: formattedDate , y: entry[metric]}
            }).concat({x: formattedTodayDate, y: todayDashboardDataResponse.data[0][metric]}) // this is here because todays data is from a seperate api call
            return [{id:metric, color:"hsl(165, 70%, 50%)", data:lineData}]
        }
        
            const createTodayIntervalChartData = (data, metric) => {
                // Pre-process data to handle 0 or negative values
                for (let i = 0; i < data.length; i++) {
                    if (data[i][metric] <= 0) {
                        const prevValue = i > 0 ? data[i - 1][metric] : 0;
                        const nextValue = i < data.length - 1 ? data[i + 1][metric] : 0;

                        // If both previous and next values are not  0, take the average
                        if (prevValue > 0 && nextValue > 0) {
                            data[i][metric] = (prevValue + nextValue) / 2;
                        } else if (prevValue === 0 && nextValue === 0) {
                            data[i][metric] = 0;
                        }
                    }
                }

                // Create chart data
                const lineData = data.map((entry) => {
                    const date = new Date(entry.timestamp ? entry.timestamp : entry._id);
                    const formattedDate = date.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' })
                                        + " " 
                                        + date.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit' });
                    return { x: formattedDate, y: entry[metric] };
                });

                // Add today's data
                const todayMetricValue = todayDashboardDataResponse.data[0][metric];
                lineData.push({ x: formattedTodayDate, y: todayMetricValue });

                return [{ id: metric, color: "hsl(165, 70%, 50%)", data: lineData }];
            }
        

            // today graph data
            const todayImpression = createTodayIntervalChartData(intervalDailyDashboardDataResponse.data, 'impression')
            const todayLike = createTodayIntervalChartData(intervalDailyDashboardDataResponse.data, 'like')
            const todayComment = createTodayIntervalChartData(intervalDailyDashboardDataResponse.data, 'comment')
            const todayShare = createTodayIntervalChartData(intervalDailyDashboardDataResponse.data, 'share')
            const todayGravity = createTodayIntervalChartData(intervalDailyDashboardDataResponse.data, 'gravity')
            setTodayGraphData([todayImpression, todayLike, todayComment, todayShare, todayGravity]);

            // all time graph data
            const allTimeImpression = createAllTimeOrTodayChartData(dailyDashboardDataResponse.data, 'impression')
            const allTimeLike = createAllTimeOrTodayChartData(dailyDashboardDataResponse.data, 'like')
            const allTimeComment = createAllTimeOrTodayChartData(dailyDashboardDataResponse.data, 'comment')
            const allTimeShare = createAllTimeOrTodayChartData(dailyDashboardDataResponse.data, 'share')
            const allTimeGravity = createAllTimeOrTodayChartData(dailyDashboardDataResponse.data, 'gravity')
            setAllTimeGraphData([allTimeImpression, allTimeLike, allTimeComment, allTimeShare, allTimeGravity])
            setCustomRangeGraphData([allTimeImpression, allTimeLike, allTimeComment, allTimeShare, allTimeGravity])

            // custom function similar to the previous one to get all time and today charts data, 
            // but added extra features so that it can filter based on day range
            // might be combined with the previous one, basically same functionality anyway
            const filteredDataByRange = (data, metric, daysAgo) => {
            const dateRange = nDaysAgo(daysAgo)
            const filteredData = [{
                id: metric, 
                color: "hsl(165, 70%, 50%)",
                data: data.filter((entry) => {
                    const entryDate = new Date(entry.x)
                    if (entryDate >= dateRange) {
                        entry.x = entryDate.toLocaleDateString("en-US", {year: 'numeric', month: '2-digit', day: '2-digit'}) 
                        return true;
                    }
                    return false;
                })
            }]
            return filteredData;
        }
        
        

            // last three days chart
            const filteredThreeDaysGraphImpressionData = filteredDataByRange(allTimeImpression[0].data, 'impression', 2)
            const filteredThreeDaysGraphLikeData = filteredDataByRange(allTimeLike[0].data, 'like', 2)
            const filteredThreeDaysGraphCommentData = filteredDataByRange(allTimeComment[0].data, 'comment', 2)
            const filteredThreeDaysGraphShareData = filteredDataByRange(allTimeShare[0].data, 'share', 2)
            const filteredThreeDaysGraphGravityData = filteredDataByRange(allTimeGravity[0].data, 'gravity', 2)
            setLastThreeDaysGraphData([filteredThreeDaysGraphImpressionData, filteredThreeDaysGraphLikeData, filteredThreeDaysGraphCommentData, filteredThreeDaysGraphShareData, filteredThreeDaysGraphGravityData])
            

            // last seven days chart
            const filteredSevenDaysGraphDataImpressionData = filteredDataByRange(allTimeImpression[0].data, 'impression', 6)
            const filteredSevenDaysGraphDataLikeData = filteredDataByRange(allTimeLike[0].data, 'like', 6)
            const filteredSevenDaysGraphDataCommentData = filteredDataByRange(allTimeComment[0].data, 'comment', 6)
            const filteredSevenDaysGraphDataShareData = filteredDataByRange(allTimeShare[0].data, 'share', 6)
            const filteredSevenDaysGraphDataGravityData = filteredDataByRange(allTimeGravity[0].data, 'gravity', 6)
            setLastSevenDaysGraphData([filteredSevenDaysGraphDataImpressionData, filteredSevenDaysGraphDataLikeData, filteredSevenDaysGraphDataCommentData, filteredSevenDaysGraphDataShareData, filteredSevenDaysGraphDataGravityData])

            setInputValue(options[0])
            
            setDataUpdated(true)
            setIsLoading(false);

            setDataPeriod(new Map([
            ['All Time', [allTimeData, allTimeGraphData]],
            ['Last 3 Days', [lastThreeDaysData, lastThreeDaysGraphData]],
            ['Last 7 Days', [lastSevenDaysData, lastSevenDaysGraphData]],
            ['Today', [todayDashboardData[0], todayGraphData]],
            ['Custom Range', [allTimeData, allTimeGraphData]]
            ]));
            
        })
        .catch(error => {
            
            setIsLoading(false);
        });
    }, [orgInputValue, selectedChannel]); // eslint-disable-line

    const [dataPeriod, setDataPeriod] = useState(new Map());


    useEffect(() => {
        if (dataUpdated) {
        
        
        setDataPeriod(new Map([
            ['All Time', [allTimeData, allTimeGraphData]],
            ['Last 3 Days', [lastThreeDaysData, lastThreeDaysGraphData]],
            ['Last 7 Days', [lastSevenDaysData, lastSevenDaysGraphData]],
            ['Today', [todayDashboardData[0], todayGraphData]],
            ['Custom Range', [customRangeData, customRangeGraphData]]
        ]));
        }
    }, [dataUpdated, allTimeData, lastThreeDaysData, lastSevenDaysData, todayDashboardData, allTimeGraphData, lastThreeDaysGraphData, lastSevenDaysGraphData, todayGraphData, customRangeData, customRangeGraphData]);

    const [customStartDate, setCustomStartDate] = useState(null);
    const [customEndDate, setCustomEndDate] = useState(null);

    // Helper function to format Date object to string in MM/DD/YYYY format
    function formatDate(date) {
        const d = new Date(date);
        const month = `${d.getMonth() + 1}`.padStart(2, '0'); // Months are 0 based index in JavaScript
        const day = `${d.getDate()}`.padStart(2, '0');
        const year = d.getFullYear();
        
        return `${month}/${day}/${year}`;
    }
    useEffect(() => {
        // Check whether the customEndDate is smaller than the customStartDate
        if (allTimeData && allTimeGraphData && customStartDate && customEndDate && new Date(customStartDate) <= new Date(customEndDate)) {
        

            // Create a new filteredAllTimeGraphData
        const filteredAllTimeGraphData = allTimeGraphData.map(arrayItem => {
        const item = arrayItem[0];  // get the object from the nested array
        return [{
            ...item,
            data: item.data.filter((dataItem) => {
            // Format the x value and the custom dates to compare
            const dataItemDate = formatDate(dataItem.x.split(" ")[0]);
            return (dataItemDate >= formatDate(customStartDate) && dataItemDate <= formatDate(customEndDate));
            }),
        }];
        });

        // Update the statistic object
        let updatedStats = {...customRangeData}; // use the state variable customRangeData
        const orderedKeys = ['impression', 'like', 'comment', 'share', 'gravity'];
        filteredAllTimeGraphData.forEach((arrayItem, index) => {
        const item = arrayItem[0];
        const sum = item.data.reduce((accum, dataItem) => accum + dataItem.y, 0);
        const key = orderedKeys[index]; // get the key based on the index
        updatedStats[key] = sum; // this line updates the clone
        });

        
        
        setCustomRangeData(updatedStats);
        setCustomRangeGraphData(filteredAllTimeGraphData);
    }
    }, [customStartDate, customEndDate]);

    const handleChannelSelection = (channel) => {
        setSelectedChannel((prevSelectedChannel) => {
        if (prevSelectedChannel.includes(channel)) {
            return prevSelectedChannel.filter((ch) => ch !== channel);
        } else {
            return [...prevSelectedChannel, channel];
        }
        });
    };


    const channelIcons = {
        facebook: <FacebookOutlined />,
        IG: <Instagram />,
        linkedin: <LinkedIn />,
    };

    if (isLoading) {
        return <Box m='1.5rem 2rem' width="100%" height='100%'><Skeleton variant="rectangular" width={500} height={300} /></Box>
    }
    return (
        <Box 
            pr='1rem'
            pl='1.5rem'
            width='100%'
            minHeight='90%'
            flexDirection="row"
            id='main box'
        >
            <Box
                sx={{
            display:"flex",
            flexDirection:'row'
            }}
                width="100%"
                id='header box'
        >
                <Box
                    mr='50px'
            >
                    <Typography
                        variant="h4"
                        color={theme.palette.secondary[100]}
                        fontWeight="bold"
                        sx={{mb:"5px"}}
                >
                        Dashboard
                    </Typography>

                    <Typography
                        variant="h6"
                        color={theme.palette.secondary[300]}
                >
                        Hi {userName},welcome to your dashboard
                    </Typography>
                </Box>

                <Box id='channel button box' sx={{display:"flex", flexDirection:"row"}}>
                    {checkAuth().channel[0].channel.map((channel) => (
                        <Button
                            onClick={() => handleChannelSelection(channel)}
                            key={channel}
                            sx={{
                    backgroundColor: selectedChannel.includes(channel) ?  'lightGrey' : theme.palette.background.primary,
                    border: selectedChannel.includes(channel) ? '2px solid white' : 'none',
                    opacity: selectedChannel.includes(channel) ? 1 : 0.5,
                    }}
                >
                            {channelIcons[channel]}
                        </Button>
                ))}
                </Box>
                <Box
                    id='autocomplete boxes'
                    sx={{ display: "flex", flexDirection: "row" }}          
            >
                    <Autocomplete 
                        disableClearable
                        options={options}
                        renderInput={(params) => <TextField {...params} label="Select a Period" />}        
                        sx={{width:200}}
                        value={inputValue}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                                }}    
                />
                    {
                    inputValue === 'Custom Range' &&
                    <Box
                        sx={{ display: "flex", flexDirection: "column", width:'30%', ml:'25px' }}
                        alignItems='center'
                        justifyContent='center'

                    >
                        <DatePicker
                            selected={customStartDate}
                            onChange={(date) => setCustomStartDate(date)}
                            placeholderText="Start Date"
                            dateFormat="dd MMMM yyyy"
                            className="date-picker"
                            style={{ width: '100%', height: '100%' }}  // Override style directly
                    />
                        <DatePicker
                            selected={customEndDate}
                            onChange={(date) => setCustomEndDate(date)}
                            placeholderText="End Date"
                            dateFormat="dd MMMM yyyy"
                            className="date-picker"
                            style={{ width: '100%', height: '100%' }}  // Override style directly
                    />
                    </Box>
                }
                
                    {role === 'admin' ?
                        <Autocomplete
                            disableClearable 
                            options={orgOptions}
                            renderInput={(params) => <TextField {...params} label="Select an Account" />}        
                            sx={{width:200}}
                            value={orgInputValue}
                            inputValue={orgInputValue}
                            zIndex={99999999}
                            onInputChange={(event, newInputValue) => {
                    setOrgValue(newInputValue)
                    window.location.reload()
                                }}    
                /> : null}
                </Box>
            </Box>


            {/* MAIN BOX GRID TEMPLATE */}
            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="80px"
                gap="15px"
                width="100%"
                id='main box grid'
                sx={{
            "& > div" : { gridColumn : isNonMediumScreens ? undefined: "span 12"}
            }}
        >
                {/* MAIN METRICS TOP LEFT */}
                {/* BOX 1 */}
                <Box
                    gridColumn="span 4"
                    gridRow="span 2"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    p="1.25rem 1rem"
                    flex = "1 1 100%"
                    backgroundColor={theme.palette.background.alt}
                    borderRadius="0.55rem"
                    sx={{boxShadow:4}}
            >
                    <Typography variant="h3" sx={{color:theme.palette.secondary[100]}} fontWeight="700">
                        Gravity Score
                    </Typography>

                    <Typography
                        variant="h3"
                        fontWeight="600"
                        sx={{color:theme.palette.secondary[200]}}
            >    
                        {dataPeriod.has(inputValue) && dataPeriod.get(inputValue)[0] ? parseInt(dataPeriod.get(inputValue)[0]['gravity']) : null}
                    </Typography>
                    <FlexBetween gap="1rem">
            
                        <Typography
                            variant='h5'
                            fontStyle="italic"
                            sx={{color:theme.palette.secondary.light}}
                >
                        </Typography>
                        <Typography>{inputValue}</Typography>
                    </FlexBetween>
                </Box>
                {/* END OF MAIN METRICS TOP LEFT */}
                {/* END OF BOX 1 */}

                {/* BOX 2 */}
                <Box
                    gridColumn="span 4"
                    gridRow="span 3"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    p="1.25rem 1rem"
                    flex = "1 1 100%"
                    backgroundColor={theme.palette.background.alt}
                    borderRadius="0.55rem"

                    sx={{boxShadow:4}}
            >
                    <Typography variant="h3" 
                        sx={{color:theme.palette.secondary[100]}} 
                        fontWeight="700"
            >
                        {/* Gravity Breakdown */}
                    </Typography>
                    {/* <PieChart /> */}

                </Box>
                {/* END OF BOX 2 */}

                {/* BOX 3 */}
                <Box
                    id='best post box'
                    gridColumn="span 4"
                    gridRow="span 6"
                    display="grid"
                    flexDirection="column"
                    justifyContent="space-between"
                    gridTemplateColumns="repeat(2, 1fr)"
                    gridTemplateRows="repeat(6, 1fr)"
                    p="1.25rem 1rem"
                    flex = "1 1 100%"
                    backgroundColor={theme.palette.background.alt}
                    borderRadius="0.55rem"
                    width='85%'
                    sx={{boxShadow:4}}
            >
                    <Box
                        id='header box'
            >
                        <Typography variant="h3" 
                            sx={{color:theme.palette.secondary[100]}} 
                            fontWeight="700"
                >
                            Best Post
                        </Typography>
                        <Box id='channel button box' sx={{display:"flex", flexDirection:"row"}}>
                            {checkAuth().channel[0].channel.map((channel) => (
                                <Button
                                    onClick={() => {
                    setSelectedBestPostChannel(channel);
                    const bestPostForChannel = bestPost.find(post => post.channel === channel);
                    setSelectedBestPost(bestPostForChannel);
                }}
                                    key={channel}
                                    sx={{
                    backgroundColor: selectedBestPostChannel.includes(channel) ?  'lightGrey' : 'white',
                    border: selectedBestPostChannel.includes(channel) ? '2px solid white' : 'none',
                    opacity: selectedBestPostChannel.includes(channel) ? 1 : 0.5,
                    }}
                >
                                    {channelIcons[channel]}
                                </Button>
                ))}
                        </Box>
            
                    </Box>  
                    <Box
                        gridColumn="span 2"
                        gridRow="span 4"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="center"

                        flex = "1 1 100%"
                        borderRadius="0.2rem"
                        backgroundColor={theme.palette[100]}
            >
                        {
                    selectedBestPostChannel === 'facebook' ?
                        <FacebookPost postId={selectedBestPost.id} width='300' height='250'/> :
                    selectedBestPostChannel === 'IG' ?
                        <InstagramPost postId={selectedBestPost.id}/> :
                    selectedBestPostChannel === 'linkedin' ?
                        <LinkedInPost postId={selectedBestPost.id} width='300' height='250' />
                    : null
                }
                    </Box>
                    <Box
                        gridColumn="span 1"
                        gridRow="span 1"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                // gridTemplateColumns="repeat(2, 1fr)"
                // gridTemplateRows="repeat(6, 1fr)"
                        flex = "1 1 100%"
                        borderRadius="0.55rem"
                        backgroundColor={theme.palette[100]}
                >
                        <List 
                            p="1rem 2rem"
                            dense='true'
                            disablePadding='true'
                >
                            {/* GRAVITY SCORE */}
                            <ListItem
                                divider='true'>
                                <ListItemAvatar>
                                    <TroubleshootOutlined />
                                </ListItemAvatar>
                                <ListItemText primary="Gravity" secondary={selectedBestPost.gravity} />
                            </ListItem>

                            {/* IMPRESSION */}
                            <ListItem
                                divider='true'>
                                <ListItemAvatar>
                                    <VisibilityOutlinedIcon />
                                </ListItemAvatar>
                                <ListItemText primary="Impression" secondary={selectedBestPost.impression} />
                            </ListItem>

                            {/* LIKE */}
                            <ListItem
                                divider='true'>
                                <ListItemAvatar>
                                    <ThumbUpOutlined />
                                </ListItemAvatar>
                                <ListItemText primary="Like" secondary={selectedBestPost.like} />
                            </ListItem>
                        </List>
                    </Box>
                    <Box
                        gridColumn="span 1"
                        gridRow="span 1"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                // gridTemplateColumns="repeat(2, 1fr)"
                // gridTemplateRows="repeat(6, 1fr)"
                        flex = "1 1 100%"
                        borderRadius="0.55rem"
                        backgroundColor={theme.palette[100]}
                >
                        <List 
                            p="1rem 2rem"
                            dense='true'
                            disablePadding='true'
                >
                            {/* SHARE */}
                            <ListItem
                                divider='true'>
                                <ListItemAvatar>
                                    <CallMadeOutlined />
                                </ListItemAvatar>
                                <ListItemText primary="Share" secondary={selectedBestPost.share} />
                            </ListItem>

                            {/* CLICK */}
                            <ListItem
                                divider='true'>
                                <ListItemAvatar>
                                    <TouchAppOutlined />
                                </ListItemAvatar>
                                <ListItemText primary={selectedBestPostChannel === 'IG' ? 'Save' : "Click"} secondary={selectedBestPostChannel === 'IG' ? selectedBestPost.saved : selectedBestPost.click} />
                            </ListItem>

                            {/* COMMENT */}
                            <ListItem
                                divider='true'>
                                <ListItemAvatar>
                                    <ChatBubbleOutlineOutlined />
                                </ListItemAvatar>
                                <ListItemText primary="Comment" secondary={selectedBestPost.comment} />
                            </ListItem>
                
                        </List>
                    </Box>
                </Box>
        
                {/* END OF BOX 3 */}
                {
                dataPeriod.has(inputValue) && dataPeriod.get(inputValue)[0] ?          
                    <StatBox
                // key={"share-statbox-" + selectedChannel.toString()}
                        title="Share"
                        value={dataPeriod.get(inputValue)[0]['share'] >= 10000
                ? (dataPeriod.get(inputValue)[0]['share'] / 1000).toFixed(2) + "k"
                : dataPeriod.get(inputValue)[0]['share']}
                        description={inputValue}
                        data={dataPeriod.get(inputValue)[1][3]}
                        icon={<CallMadeOutlined/>}
            />
            :
            null
            }

                {
                dataPeriod.has(inputValue) && dataPeriod.get(inputValue)[0] ?          
                    <StatBox
                // key={"comment-statbox-" + selectedChannel.toString()}
                        title="Comment"
                        value={dataPeriod.get(inputValue)[0]['comment'] >= 10000
                ? (dataPeriod.get(inputValue)[0]['comment'] / 1000).toFixed(2) + "k"
                : dataPeriod.get(inputValue)[0]['comment']}
                        description={inputValue}
                        data={dataPeriod.get(inputValue)[1][2]}
                        icon={<ChatBubbleOutlineOutlined/>}
            />
            :
            null
            }
                <Box
                    gridColumn="span 4"
                    gridRow="span 3"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    p="1.25rem 1rem"
                    flex = "1 1 100%"
                    backgroundColor={theme.palette.background.alt}
                    borderRadius="0.55rem"

                    sx={{boxShadow:4}}
            >

                </Box>
                { 
                dataPeriod.has(inputValue) && dataPeriod.get(inputValue)[0] ?          
                    <StatBox
                // key={"impression-statbox-" + selectedChannel.toString()}
                        title="Impression"
                        value={dataPeriod.get(inputValue)[0]['impression'] >= 10000
                ? (dataPeriod.get(inputValue)[0]['impression'] / 1000).toFixed(2) + "k"
                : dataPeriod.get(inputValue)[0]['impression']}
                // increase={dataPeriod.get(inputValue).map(a => a.impressionChange)+'%'}
                        description={inputValue}
                        data={dataPeriod.get(inputValue)[1][0]}
                        icon={<VisibilityOutlinedIcon/>}
                >
                    </StatBox>
            :
                null  
            }
                {
            dataPeriod.has(inputValue) && dataPeriod.get(inputValue)[0] ?          
                <StatBox
            // key={"like-statbox-" + selectedChannel.toString()}
                    title="Like"
                    value={dataPeriod.get(inputValue)[0]['like'] >= 10000
                    ? (dataPeriod.get(inputValue)[0]['like'] / 1000).toFixed(2) + "k"
                    : dataPeriod.get(inputValue)[0]['like']}
            // increase={dataPeriod.get(inputValue).map(a => a.likeChange)+'%'}
                    description={inputValue}
                    data={dataPeriod.get(inputValue)[1][1]}
                    icon={<ThumbUpOutlined/>}
            >

                </StatBox>
            : 
                null
            }
            </Box>

            {/* END MAIN BOX GRID TEMPLATE */}
        </Box>

    )
}

export default Dashboard
