import React from 'react';

const LinkedInPost = ({ postId, width, height }) => {
return (
    <div>
        <iframe 
            src={`https://www.linkedin.com/embed/feed/update/${postId}`} 
            height={height} 
            width={width} 
            allowfullscreen="" 
            title="Embedded post">
        </iframe>
    </div>
);
};

export default LinkedInPost;