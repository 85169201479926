import React from 'react'
import {
    Box,
    Button,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup"
import { useNavigate } from 'react-router-dom'


import axios from 'axios'

import { setUsername, setUserId, setToken, setRole, setOrg, setChannel} from "../../lib/auth.js"
import { baseUrl } from 'config.js'

const loginSchema = yup.object().shape({

    email: yup.string().required('required'),
    password: yup.string().required('required'),
})

const initialvaluesSchema = {
    email: "",
    password: "",
}

const LoginPage = () => {

    const {palette} = useTheme()
    const navigate = useNavigate()

    
    const login = (formData) => {
        return axios.post(`${baseUrl}/auth/login`, formData)
        }
        // baseUrl hasnt been imported yet

        const handleFormSubmit = async (values) => {
            values.email = values.email.toLowerCase()
            try{
            const res = await login(values)
            setUsername(res.data.firstName)
            setUserId(res.data.userId)
            setToken(res.data.token)
            setRole(res.data.role)
            // setUserLoggedIn(res.data.role)
            setOrg(res.data.org)
            setChannel(res.data.channel)
            
            }
            
            catch(err){
                console.log(err)
            }
            navigate('/dashboard')

        }

return (
    <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        minWidth="500px"
    >
        <Formik
            onSubmit= {handleFormSubmit}
            initialValues = {initialvaluesSchema}
            validationSchema = {loginSchema}
        >
            {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm
            }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        // gridTemplateRows="repeat(4, minmax(0,1fr))"
                        mt= '40px' // adds 30px margin-top
                        // ml= '300px'
                        // mr= '300px'
                        mb= '40px'
                    >
                        <TextField
                            label = "Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value = {values.email}
                            name= "email"
                        />
                        <TextField
                            label = "Password"
                            type='password'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value = {values.password}
                            name= "password"
                        />
                        <Button
                            // ml= '80px'
                            // mr= '80px'
                            type="submit"
                            sx={{
                            p: "1rem",
                            backgroundColor:'#00a0ec',
                            // color: palette.background.alt,
                            "&:hover": { color: palette.primary.main },
                            }}
                        >
                            <Typography
                                sx={{
                                textDecoration: "underline",
                                // color: palette.primary.main,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                                }}
                            >
                                LOGIN
                            </Typography>
                        </Button>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            gap='2rem'
                        >
                            <Typography
                                sx={{
                                textDecoration: "underline",
                                // color: palette.primary.main,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                                }}
                            >
                                <a href="https://www.10point6.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
                                    Terms of Service
                                </a>
                            </Typography>
                            <Typography
                                sx={{
                                textDecoration: "underline",
                                // color: palette.primary.main,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                                }}
                            >
                                <a href="https://www.10point6.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                                    Privacy Policy
                                </a>
                            </Typography>
                        </Box>
                    </Box>
                    
                </form>
            )}
        </Formik>
    </Box>
)
}

export default LoginPage