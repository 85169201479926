import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { checkAuth } from 'lib/auth';
import { IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const InstagramPost = ({ postId, width, height, pageAccessToken}) => {

    const org = checkAuth().org
    const accessTokenList = new Map ([
        ['ra','EAAGsL0zAqDABALSqR3kbYJNstr5lE1MPugRWYbdBDFQjSYxJ3TbtvteRvYGmBlz13HW5nZCGk13bPcawcfVM0yKNlAqbZAAwT47367CJzuco3WjWJKPhyecWEheKibIDVhBh2BBzwgNYch27ZAPBjNSaDozIfhwDDYZBYhbnZBheojGyXxUCB'],
        ['roh', 'EAAGsL0zAqDABALSqR3kbYJNstr5lE1MPugRWYbdBDFQjSYxJ3TbtvteRvYGmBlz13HW5nZCGk13bPcawcfVM0yKNlAqbZAAwT47367CJzuco3WjWJKPhyecWEheKibIDVhBh2BBzwgNYch27ZAPBjNSaDozIfhwDDYZBYhbnZBheojGyXxUCB'],
        ['zg','EAAGsL0zAqDABAELo4VgG1D3CJ1ehxyIS8uyp4RgDGD4zDyhvDJmaFa6ZAo56ZA57deSpAh7vvAffzaq1SFZBnJGLp3RbfFnkldzD6187dfBeOfM0TQpeFOPg40izYe77PNGZCNdfjIcq4OfDIIxOjIPivBXTWpPcixXlZCqEgTZCS60dCQjzXLVKVCxpnIFiIZD'],
        ['witness', 'EAAGsL0zAqDABO5ZA02WepdgVRUAUVzTR6q0bySi1d3YiG4IwVwLeA4yy9AuqWuTCXFSPQD42m4ZBZBlfnRzSTd37rVJgXlqbrZCLy46gIuvSSCZB1ubx6PAQBgVi2azkv3Feod1k3Rf2eIthLTABvohOz6UBOfV84apPqbGme4eA7JQNZBn9AAdTbi33eSVtSTh7fgZCsUZD']
    ])
    const [post, setPost] = useState(null);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [accessToken, setAccessToken] = useState(null);
    useEffect(() => {
        if (!pageAccessToken) {
            setAccessToken(accessTokenList.get(org))
        } else {
            setAccessToken(pageAccessToken)
        }
    })
    , [org, pageAccessToken]

    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const response = await axios.get(`https://graph.facebook.com/v16.0/${postId}?fields=id,media_type,media_url,thumbnail_url,children{media_type,media_url},caption,permalink&access_token=${accessToken}`);
                const post = response.data;
                if (post.media_type === 'CAROUSEL_ALBUM' && post.children && post.children.data) {
                    const children = await Promise.all(post.children.data.map((child) => {
                        return axios.get(`https://graph.facebook.com/v16.0/${child.id}?fields=id,media_type,media_url,thumbnail_url&access_token=${accessToken}`);
                    }));
                    post.children.data = children.map((response) => response.data);
                }
                setPost(post);
            } catch (error) {
                console.error('Error fetching Instagram post:', error);
            }
        };
        if (accessToken) {  
            fetchPostData();
        }
    }, [postId, accessToken]); //eslint-disable-line
    
    if (!post) {
        return <div>Loading...</div>;
    }
    
    const currentMedia = post.media_type === 'CAROUSEL_ALBUM' ? post.children.data[carouselIndex] : post;
    const mediaUrl = currentMedia.media_type === 'VIDEO' ? currentMedia.thumbnail_url : currentMedia.media_url;
    
    return (
        <div style={{ width: `${width}px`, height: `${height}px`, overflow: 'auto', position: 'relative', pointerEvents: 'none' }}>
        <a href={post.permalink} target="_blank" rel="noreferrer" style={{ pointerEvents: 'all' }}>
            <img src={mediaUrl} alt="Post" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </a>
        {post.media_type === 'CAROUSEL_ALBUM' && (
            <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
            {carouselIndex !== 0 && (
                <IconButton onClick={(e) => { e.stopPropagation(); setCarouselIndex((carouselIndex - 1 + post.children.data.length) % post.children.data.length); }} style={{ pointerEvents: 'all', position: 'absolute', left: 0 }}>
                <ArrowBackIos />
                </IconButton>
            )}
            {carouselIndex !== post.children.data.length - 1 && (
                <IconButton onClick={(e) => { e.stopPropagation(); setCarouselIndex((carouselIndex + 1) % post.children.data.length); }} style={{ pointerEvents: 'all', position: 'absolute', right: 0 }}>
                <ArrowForwardIos />
                </IconButton>
            )}
            </div>
        )}
        <p style={{ pointerEvents: 'all' }}>{post.caption}</p>
        </div>
    );
    
    };
    
    export default InstagramPost;