import React, {useState, useEffect} from 'react'
import{ Box, Rating } from "@mui/material" //useTheme
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Header from 'components/Header'
import { useNavigate, useParams } from 'react-router-dom'

import { getFacebookPosts } from 'state/api'

import { checkAuth } from 'lib/auth'
import RocketIcon from '@mui/icons-material/Rocket';
import { Rocket } from '@mui/icons-material'
import { calculateDifferenceInDaysFromDateObjects } from 'lib/helperFunction'

const Posts = () => {
    // const theme = useTheme()
    const navigate = useNavigate()

    const auth = checkAuth()
    const org = auth? auth.org : undefined

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const {  channel } = useParams()

    useEffect(() => {
        getFacebookPosts(org, channel)
        .then(response => {
            setData(response.data);
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [channel]); //eslint-disable-line


    const columns = [
        {
            field: "created_time",
            headerName: "Created Time",
            minWidth: 160,
            valueFormatter: (params) => {
            const date = new Date(params.value);
              // Format the date with hours and minutes
            const formattedDate = date.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
            });
            return formattedDate;
            },
        },
        {
            field:"caption",
            headerName:"Content",
            minWidth:250
        },
        // Star rating column definition
        {
            field: "star_rating",
            headerName: "Rating",
            // valueGetter: (params) => {
            //     return params.row.star_rating + params.row.gravity / 1000;
            // },
            renderCell: (params) => {
            return (
                <Rating
                    value={params.row.star_rating}
                    precision={0.5}
                    readOnly
                />
            );
            },
            minWidth: 150,
            sortingOrder: ["desc", "asc", null],
        },
        {
            field: "impression_paid",
            headerName: "Boosted",
            valueGetter: (params) => {
                return params.row.impression_paid && params.row.impression_paid > 0 ? 1 : 0;
            },
            renderCell: (params) => {
                return params.value ? <RocketIcon /> : <RocketIcon sx={{opacity:0.1}}/>
            },
            sortComparator: (v1, v2, cellParams1, cellParams2) => {
                return cellParams1.value - cellParams2.value;
            },
            minWidth: 30,
        },    
        {
            field:"gravity",
            headerName:"Gravity",
              // flex:0.5
        },
        {
            field:"days_live",
            headerName:"Days Live",
            valueGetter: (params) => {
                return calculateDifferenceInDaysFromDateObjects(new Date(), new Date(params.row.created_time))
            }
        },
        {
            field:"gravity/day",
            headerName:"Gravity/ Day",
            valueGetter: (params) => {
                const daysLive = calculateDifferenceInDaysFromDateObjects(new Date(), new Date(params.row.created_time))
                return daysLive > 0 ? parseFloat((params.row.gravity / daysLive).toFixed(2)) : 0

            }  
        },
        {
            field:'post_type',
            headerName:"Type",
            // flex:0.5
        },
        {
            field:"impression",
            headerName:"Impression",
            // flex:0.5
        },
        {
            field:"like",
            headerName:"Like",
            // flex:0.5
        },
        {
            field:"share",
            headerName:"Share",
            // flex:0.5
        },
        {
            field: channel === 'ig' ? 'saved': "click",
            headerName:channel === 'ig' ? 'Save': "Click",
            // flex:0.5
        },
        {
            field:"comment",
            headerName:"Comment",
            // flex:0.5
        }, 
        {
            field:"video_length",
            headerName:"Video Length",
            // flex:0.5
        },
        {
            field:"video_view",
            headerName:"Video View",
            // flex:0.5
        },
        {
            field:"id",
            headerName:"Post ID",
            hide:true
        }

    ]

    const handleRowClick = (params) =>{
        navigate(`/individual-post/${channel}`,{state:{id:`${params.row.id}`}})
    }

    const [sortModel, setSortModel] = useState([
        {
          field: 'gravity',
          sort: 'desc',
        },
      ]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <Box m="1.5rem 2.25rem">
            {channel === 'facebook'?
                <Header title="LIVE FACEBOOK POSTS" subtitle="List of live Facebook posts. Click a row to see the post detail" />
            : channel === "ig" ?
                <Header title="LIVE INSTAGRAM POSTS" subtitle="List of live Instagram posts. Click a row to see the post detail" />
            : channel === "linkedin" ?
                <Header title="LIVE LINKEDIN POSTS" subtitle="List of live Linkedin posts. Click a row to see the post detail" />
            : null
            }
            <Box
                mt="20px"
                height="75vh"
                sx={{ width:1}}
            >
                <DataGrid
                    loading={isLoading || !data}
                    getRowId={(row) => row._id}
                    rows={data || []}
                    columns={columns}
                    components={{
                        toolbar: GridToolbar,
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    onRowClick={handleRowClick}
                />
            </Box>
        </Box>
        )
}

export default Posts