/* eslint-disable react/react-in-jsx-scope */
import {
    Box,
    Button,
    TextField
} from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup"

import { checkAuth } from 'lib/auth'
import { Navigate } from 'react-router-dom'

const registerSchema = yup.object().shape({
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    email: yup.string().required('required'),
    password: yup.string().required('required'),
    role: yup.string().required('required'),
    org: yup.string().required('required'),
})

const initialvaluesRegister = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role:"",
    org: ""
}

const RegisterPage = () => {
    const notAdmin = <Navigate to ='/dashboard'/> 

    const auth = checkAuth()
    const role = auth.role
    if (role !== 'admin') {
        return <Navigate to ='/dashboard'/> 

    }
    const register = async (values, onSubmitProps) => {
        const response = await fetch('http://localhost:5003/auth/register', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
        });
        const data = await response.json();

        // onSubmitProps.resetForm();
    };


    const handleFormSubmit = async (values, onSubmitProps) => {
        try{
            await register(values)
            onSubmitProps.resetForm()
        }
        catch(error){
            console.log('error', error)
        }
    }

return (
    <Formik
        onSubmit= {handleFormSubmit}
        initialValues = {initialvaluesRegister}
        validationSchema = {registerSchema}
    >
        {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
    }) => (
        <form onSubmit={handleSubmit}>
            <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0,1fr))"
                mt= '40px' // adds 30px margin-top
                ml= '50px'
            >
                <TextField
                    label = "First Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value = {values.firstName}
                    name= "firstName"
                />
                <TextField
                    label = "Last Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value = {values.lastName}
                    name= "lastName"
                />
                <TextField
                    label = "Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value = {values.email}
                    name= "email"
                />
                <TextField
                    label = "Password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value = {values.password}
                    name= "password"
                />
                <TextField
                    label = "Role"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value = {values.role}
                    name= "role"
                />
                <TextField
                    label = "Org"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value = {values.org}
                    name= "org"
                />
            </Box>
            <Box>
                <Button
                    fullWidth
                    type="submit"
                >
                    REGISTER
                </Button>
            </Box>
        </form>
    )}
    </Formik>
)
}

export default RegisterPage