/**
 * 
 * This function takes in a date in ISO format and returns a string in the desired format.
 * The desired format is specified by the second parameter.
 * The second parameter can be one of the following:
 * DMYWord: 01 January 2020
 * DMYShort: 01 Jan 2020
 * YMDHM: 01/01/2020 00:0
 * 
 * @param {string|Date} isoDate 
 * @param {string} format, one of the following: DMYWord, DMYShort, DMYHM
 * @returns {string} date in the desired format
 * 
 * @example
 * convertDate('2020-01-01T00:00:00.000Z', 'DMYWord');
 * returns 01 January 2020
 * @example
 * convertDate('2020-01-01T00:00:00.000Z', 'DMYShort');
 * returns 01 Jan 2020
 * @example
 * convertDate('2020-01-01T00:00:00.000Z', 'DMYHM');
 * returns 01/01/2020 00:00
 */
export const convertDate = (isoDate, format) => {
    // Check if isoDate is not a date object or not parseable by Date.parse()
    if (!isoDate || (typeof isoDate === 'string' && isNaN(Date.parse(isoDate)))) {
        return null;
    }

    // Parse the isoDate and check if the resulting date is valid
    const date = new Date(isoDate);
    if (isNaN(date.getTime())) {
        return null; // or throw an error if an invalid date is not acceptable
    }
    switch (format) {
        case 'DMYWord':
            return convertDateToDMYWhereMonthIsWord(isoDate);
        case 'DMYShort':
            return convertDateToDMYWhereMonthIsShortened(isoDate);
        case 'DMYShortHM':
            return convertDateToDMYWhereMonthIsShortenedHM(isoDate);
        case 'DMYHM':
            return convertDateToDMYHM(isoDate);
        default:
            throw new Error('Invalid date format for convertDate() second parameter.');
    }
};

// Helper Functions
const convertDateToDMYWhereMonthIsWord = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
};

const convertDateToDMYWhereMonthIsShortened = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
};

const convertDateToDMYWhereMonthIsShortenedHM = (isoDate) => {
    const date = new Date(isoDate);
    const paddedMinutes = String(date.getMinutes()).padStart(2, '0');
    const paddedHours = String(date.getHours()).padStart(2, '0');
    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }) + ` ${paddedHours}:${paddedMinutes}`;
};

const convertDateToDMYHM = (isoDate) => {
    const date = new Date(isoDate);
    const paddedMinutes = String(date.getMinutes()).padStart(2, '0');
    const paddedHours = String(date.getHours()).padStart(2, '0');
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${paddedHours}:${paddedMinutes}`;
};


/**
 * Calculates the difference in days between two date objects.
 * @param {number} timestamp1 - The timestamp of the first date object.
 * @param {number} timestamp2 - The timestamp of the second date object.
 * @returns {number} The difference in days between the two date objects.
 */
export const calculateDifferenceInDaysFromDateObjects = (timestamp1, timestamp2) => {
    const date1 = new Date(timestamp1);
    const date2 = new Date(timestamp2);

    const date1Copy = new Date(date1.setHours(0, 0, 0, 0));
    const date2Copy = new Date(date2.setHours(0, 0, 0, 0));

    const differenceInTime = date1Copy.getTime() - date2Copy.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays === 0) return 1;
    return Math.abs(Math.floor(differenceInDays));
}


/**
 * Takes in a number or a number-like string and returns a formatted number with commas. 
 * Optionally rounds the number to a specified number of decimal places.
 * 
 * @param {number|string} num - The number or number-like string to format.
 * @param {number} [dp=0] - Optional. The number of decimal places to round to. Default is 0.
 * @param {boolean} [comma=false] - Optional. Whether to format the number with commas. Default is false.
 * @returns {string} Formatted number with optional commas and rounded to 'dp' decimal places.
 * @example formatNumber(1234567.1234567, 3, true) returns '1,234,567.123'
 */

export const parseAndFormatNumber = (num, dp = 3, comma = false) => {
    if (num == null || num === '') return null;

    num = Number(num);  // Convert num to a number if it's a number-like string

    if (isNaN(num)) return null;  // Return null if num couldn't be converted to a number
    
    const absNum = Math.abs(num); // Absolute value of num

    // Check if it's a round number or a float with 0 to dp decimal places
    if (absNum >= 1 && (Number.isInteger(num) || (num.toFixed(dp) - Math.floor(num)) === 0)) {
        num = Math.floor(num);
    } else if (absNum < 0.001) {
        // If it's a float less than 0.001, format it with up to 10 decimal places
        num = parseFloat(num.toFixed(10));
    } else {
        // Otherwise, if it's a float with more than dp decimal places, format it with up to dp decimal places
        num = parseFloat(num.toFixed(dp));
    }

    // Format the number with commas if required
    if (comma) {
        let parts = num.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    return num.toString();
};

/**
 * titleise python style variable names and remove underscores
 * 
 * @param {string} string 
 * @returns {string}
 * @example capitaliseAndRemoveUnderscore('hello_world') returns 'Hello World'
 */
export const capitaliseAndRemoveUnderscore = (string) => {
    
    const words = string.split('_');
    const parsedString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

    return parsedString;
}

/**
 * parses camelCase to Title Case
 * @param {string} input 
 * @returns {string}
 * @example parseCamelCaseToTitle('helloWorld') returns 'Hello World'
 */
export const parseCamelCaseToTitle = (input) => {
    // Handle non-string types:
    if (typeof input === 'boolean') return input ? 'True' : 'False';
    if (input == null) return null;
    if (typeof input === 'number') return String(input);
    if (typeof input !== 'string') return JSON.stringify(input); // Converts objects and arrays to JSON

    // Handle camelCase strings:
    let titleCase = input.replace(/([A-Z])/g, ' $1')
        .trim()
        .replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));

    return titleCase;
}


