import React from 'react'
import { Box, Typography, useTheme, CircularProgress } from '@mui/material'
import FlexBetween from './FlexBetween'
import LineChart from './LineChart'



const StatBox = ({data, title, value, increase, icon, description}) => {
    const theme = useTheme()

    const chart = data ? <LineChart data={data} /> : <CircularProgress />
    return (
    <Box
        gridColumn="span 2"
        gridRow="span 2"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p="1.25rem 1rem"
        flex = "1 1 100%"
        bgcolor={theme.palette.background.alt}
        borderRadius="0.55rem"
        sx={{boxShadow:3}}
    >
        <FlexBetween gap='0.1rem'>
            <Typography variant="h6" sx={{color:theme.palette.secondary[100]}}>
                {title}
            </Typography>
                {icon}
                <Typography
            variant="h4"
            fontWeight="600"
            sx={{color:theme.palette.secondary[200]}}
        >
            {value}
        </Typography>
        </FlexBetween>
        <Box
        sx={{width:'99%', height:'75%'}}
        >
            {chart}
        </Box>
        <FlexBetween gap="1rem">
            <Typography
                variant='h7'
                fontStyle="italic"
                sx={{color:theme.palette.secondary.light}}
            >
                {increase}
            </Typography>
            
            
            <Typography variant='subtitle2'>{description}</Typography>
        </FlexBetween>
    </Box>
        )
}

export default StatBox