import React from 'react'
import LineChart from 'components/LineChart'
import { Box } from '@mui/material'
import PieChart from 'components/PieChart'

import { useGetFacebookQuery, useGetInstagramQuery} from 'state/api'



const Gravity = () => {


    return (
        <Box height='75vh'>
        </Box>
    )
}

export default Gravity