import React, { useEffect, useState } from 'react'
import { Box, Button, Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { checkAuth } from 'lib/auth'
import { controllerCreatedPost } from 'state/api'
import Header from 'components/Header'
import { convertDate } from 'lib/helperFunction'
import { Cancel, Check, Delete, MoreHoriz, Visibility } from '@mui/icons-material'
import ChannelLogo from 'components/ChannelLogo'
import { calculateTimeRemaining } from 'lib/helperFunctions/calculateTimeRemaining'

const headerColumns = [
    {
        headerName:"Post Name",
        field: 'createdPostName',
    },
    {
        headerName: 'Created At',
        field: 'createdAt',
    },
    {
        headerName: 'Status',
        field: 'rowStatus',
    },
    {
        headerName: 'Scheduled Time',
        field: 'scheduledPost',
    },
    // {
    //     headerName: 'Channels',
    //     field: 'channels',
    // },
    // {
    //     headerName:"Caption",
    //     field: 'caption',
    // },
    {
        headerName:"Preview Media",
        field: 'previewMedia',
        colWidth: 100
    }
]

const statusChips = [
    {
        status:"Success",
        chip: <Chip label="Success" sx={{bgcolor:"#90EE90"}} icon={<Check/>} />
    },
    {
        status:"Pending",
        chip: <Chip label="Pending" sx={{bgcolor:"#FFD966"}} icon={<MoreHoriz/>} />
    },
    {
        status:"Error",
        chip: <Chip label="Error" sx={{bgcolor:"#E06666"}} icon={<Cancel/>} />
    },
]


const TablePostStatusRow = ({
    row,
    setShowDisplayMedia,
    showDisplayMedia
}) => {
    const navigate = useNavigate()
    const hasScheduledPostPast = new Date(row.scheduledPost) < new Date()

    row.rowStatus = row.channel.map((channel) => {
        // Find the appropriate chip
        let chip = statusChips.find(chip => chip.status === channel.status);

        // If the channel is Facebook, the status is 'Success', and there is no scheduled post past
        if (channel.channel === 'facebook' && channel.status === 'Success' && !hasScheduledPostPast) {
            // Find the 'Pending' chip
            let pendingChip = statusChips.find(pendingChip => pendingChip.status === 'Pending');
            if (pendingChip) {
                chip = pendingChip;
            }
        }

        const channelAndStatus = {
            channel: channel.channel,
            status: chip ? chip : null, // Use the found chip, if any
            postId: channel?.postId
        };
        return channelAndStatus;
    });



    // row.channels = row.channel.map((channel) => {
    //     return channel.channel
    // })

    row.previewMedia = <IconButton onClick={() => row._id === showDisplayMedia ? setShowDisplayMedia(null) : setShowDisplayMedia(row._id)}> <Visibility/> </IconButton>    
    return (
        <React.Fragment>
            <TableRow>
                {
                    headerColumns.map((column, index) => {
                        let value = row[column.field]
                        // rows that need processing
                        switch(column.field) {
                            case 'createdAt':
                            case 'scheduledPost':
                                value = convertDate(value, 'DMYShortHM')
                                break
                            // case 'rowStatus':
                            //     statusChips.find(chip => {
                            //         if (chip.status === value) {
                            //             value = chip.chip
                            //         }
                            //     })
                            //     break
                            case 'caption':
                                // only take first 100 characters
                                value = value.substring(0, 100)
                                break
                        }
                        return (
                            <TableCell key={index}>
                                {
                                    column.field === 'rowStatus' && column.field ? (
                                        <Box display='flex' flexDirection='column'>
                                            {
                                                value.map((channel, index) => (
                                                    // todo: we will add an id field to channel to control disabled
                                                    <Box key={index} mb='0.5rem' flexDirection={'row'}>
                                                        <IconButton
                                                            disabled={channel?.postId ? false : true}
                                                            // open a new tab 
                                                            onClick={() =>  navigate(`/individual-post/${channel.channel === "IG" ? 'ig' : channel.channel}`,{state:{id:`${channel.postId}`, fromCreatedPost:true}})}
                                                        >
                                                            <ChannelLogo channel={channel.channel}/>
                                                        </IconButton>
                                                        {channel?.status?.chip}
                                                    </Box>

                                                ))
                                            }
                                        </Box>
                                    ) 
                                    : (column.field === 'scheduledPost' && !hasScheduledPostPast && row.scheduledPost) ? 
                                        <Box
                                            display='flex'
                                            flexDirection='column'
                                            justifyContent='center'
                                        >
                                            <Typography>
                                                {value}
                                            </Typography>
                                            <Typography>
                                                {"In " + calculateTimeRemaining(row.scheduledPost)}
                                            </Typography>
                                        </Box>
                                    : 
                                        <Typography>
                                            {value}
                                        </Typography>
                                }
                            </TableCell>
                            
                        )
                    })
                }
            </TableRow>
            { showDisplayMedia === row._id &&
                <Box
                    height='250px'
                    width='250px'
                    bgcolor='salmon'
                >

                </Box>
            }
        </React.Fragment>
    )
}

const CreatedPostStatus = () => {

    const auth = checkAuth()
    const org = auth?.org
    

    useEffect(() => {
        controllerCreatedPost({
            action:'query',
            query: {org: org},
            org: org
        })
        .then(res => {
            setCreatedPost(res.data)
        })
        .catch(err => {
            console.log(err)
        })
    }, [])
    
    const [createdPost, setCreatedPost] = useState([])
    console.log('createdPost', createdPost)

    const [showDisplayMedia, setShowDisplayMedia] = useState(null)

    const navigate = useNavigate()

    if (createdPost.length === 0) {
        return (
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
            >
                <h1> No Post Created Yet </h1>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => navigate('/create-post')}
                >
                    Create Post
                </Button>
            </Box>
        )
    }

    return (
        <Box
            id='main-box'
            pr='5rem'
            pl='5rem'
            pt='1.5rem'
            pb='3rem'
            display='flex'
            minHeight= "80vh"
            flexDirection='column'
        >
            <Header title='Created Post Status' />
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {
                                    headerColumns.map((column, index) => (
                                        <TableCell 
                                            key={index} 
                                            width={column.colWidth ? column.colWidth : 300}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '1rem'
                                            
                                            }}
                                        >
                                            {column.headerName}
                                        </TableCell>
                                    ))
                                
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                createdPost.map((row, index) => (
                                    <TablePostStatusRow 
                                        key={index} 
                                        row={row} 
                                        setShowDisplayMedia={setShowDisplayMedia}
                                        showDisplayMedia={showDisplayMedia}
                                    />
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box> 
    )
}

export default CreatedPostStatus