import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TextField, Typography } from '@mui/material'
import { checkAuth } from 'lib/auth'
import { fetchAllAttributesBasicMetrics } from 'state/api'
import Header from 'components/Header'
import { capitaliseAndRemoveUnderscore, convertDate, parseAndFormatNumber, parseCamelCaseToTitle } from 'lib/helperFunction'
import { ResponsiveBar } from '@nivo/bar'

import EditIcon from '@mui/icons-material/Edit';
import { ChannelBreakdownPieChart } from './ChannelBreakdownPieChart'
import ChannelLogo from 'components/ChannelLogo'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PostMetricsIcons from 'components/PostMetricsIcons'
import { AddCircleOutlineOutlined, Delete, DeleteOutline } from '@mui/icons-material'

const headerCellSyles = {
    borderBottom: "2px solid black",
    fontWeight: "bold",
    fontSize: "16px",
};

const headerColumnNames = [
    {
        columnName: "Attribute",
        metric:'attribute',
        number: false,
        graph: false
    },
    {
        columnName: "Impression",
        metric:'impression'
    },
    {
        columnName: "Like",
        metric:'like'
    },
    {
        columnName: "Share",
        metric:'share'
    },
    {
        columnName: "Comment",
        metric:'comment'
    },
    {
        columnName: "Gravity",
        metric:'gravity'
    },
    {
        columnName: 'Posts Made',
        metric:'posts'
    },
    {
        columnName: 'Gravity/Post',
        metric:'gravityPerPost'
    }

]



const TableDataRow = ({ data, setSelectedPage, setSelectedPostAttribute }) => {
    return (
        <React.Fragment>
            <TableRow
                // on hover to grey and cursor to pointer
                sx={{
                    '&:hover': {
                        backgroundColor: '#ccc',
                        cursor: 'pointer'
                    }
                }}
                onClick={() => {
                    setSelectedPage('Individual Attribute')
                    setSelectedPostAttribute(data)
                }
                }
            >
                {
                    headerColumnNames.map((header) => {
                        return (
                            <TableCell key={header.columnName} sx={{fontSize:'14px'}}>
                                {
                                    header.columnName === 'Posts Made' ?
                                        // length of the posts array
                                        data.posts.length
                                        :
                                    header.number===false ?
                                        data[header.metric]
                                        : 
                                        parseAndFormatNumber(data[header.metric], 2, true)  
                                }
                            </TableCell>
                        );
                    })
                }
            </TableRow>
        </React.Fragment>
    );
}


const PostAttributesAnalysis = () => {

    const [postAttributes, setPostAttributes] = useState([])
    const [postAttributesToShow, setPostAttributesToShow] = useState([])
    const [loading, setLoading] = useState(true)
    const auth = checkAuth()
    useEffect(() => {
        fetchAllAttributesBasicMetrics({org: auth.org, channels: auth.channel[0].channel})
            .then(response => {
                const processedData = response.data.map((attribute) => {
                    return {
                        ...attribute,
                        gravityPerPost: attribute.gravity / attribute.posts.length
                    }
                }).sort((a, b) => {
                    return b.gravity - a.gravity
                })
                setPostAttributes(processedData)
                setPostAttributesToShow(processedData)
                setLoading(false)
                setSelectedPostAttribute(processedData[0])
            })
            .catch(error => {
                console.log(error)
            })
    }
    , [])

    console.log(postAttributes)

    const [selectedPage, setSelectedPage] = useState('Overview')
    // to show individual attribute
    const [selectedPostAttribute, setSelectedPostAttribute] = useState(null)
    const [selectingPostAttributeMode, setSelectingPostAttributeMode] = useState(false)

    const overviewHeader = [
        {
            name:'Overview'
        },
        {
            name:"Individual Attribute"
        }
    ]


    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('gravity');

    // if post le
    const handleSort = (array, comparator) => {
        const stabilizedArray = array.map((el, index) => [el, index]);
        stabilizedArray.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedArray.map((el) => el[0]);
    };
    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };
    
    const descendingComparator = (a, b, orderBy) => {
        if (orderBy === "posts") {
            return b[orderBy].length - a[orderBy].length;
        }
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };
    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
    
        setOrder(newOrder);
        setOrderBy(property);
    
        // Perform the sorting in a callback to ensure it uses the latest state
        setPostAttributesToShow((prevAttributes) => {
            return handleSort(prevAttributes, getComparator(newOrder, property));
        });
    };

    
    // for bar chart
    // find impression and gravity by default
    const [selectedMetrics, setSelectedMetrics] = useState(headerColumnNames.filter((header) => header.metric === 'gravity' || header.metric === 'impression'))
    console.log('selectedMetrics', selectedMetrics)
    const [selectedAttributes, setSelectedAttributes] = useState([])
    console.log('selectedAttributes', selectedAttributes)
    const [numberOfAttributesToShow, setNumberOfAttributesToShow] = useState(10)

    useEffect(() => {
        if (postAttributesToShow.length > 0) {
            // find the top 10, its sorted by the table so just slice
            // postAtributesToShow is 10 by default, hence 10 is hardcoded
            const top10Attributes = postAttributesToShow
                .slice(0, 10)
                .map((attribute) => {
                    // delete the posts array
                    return{
                        ...attribute,
                        posts: undefined
                    }
                })
            setSelectedAttributes(top10Attributes)
        }
    }, [postAttributesToShow])

    useEffect(() => { // for changing bar chart when number of attributes to show changes
        const attributesToShow = postAttributesToShow
            .slice(0, numberOfAttributesToShow)
            .map((attribute) => {
                // delete the posts array
                return{
                    ...attribute,
                    posts: undefined
                }
            })
        setSelectedAttributes(attributesToShow)
    
        
    }, [numberOfAttributesToShow])

    const [attributesBarChartData, setAttributesBarChartData] = useState([])
    console.log('attributesBarChartData', attributesBarChartData)
    const [attributesBarChartMetrics, setAttributesBarChartMetrics] = useState([])

    useEffect(() => {
        const processSelectedMetricsIntoBarChartData = (selectedMetrics) => {
            // Transform metric keys
            const metricKeys = selectedMetrics.map(metric => parseCamelCaseToTitle(metric.metric));
        
            // Transform selectedAttributes into the format expected by Nivo
            const transformedData = selectedAttributes.map(item => {
                const transformedItem = { attribute: (item.attribute) };
                
                metricKeys.forEach((key, index) => {
                    // Use the original metric name for data mapping
                    const originalMetricName = selectedMetrics[index].metric;
                    transformedItem[key] = item[originalMetricName];
                });
        
                return transformedItem;
            });
        
            setAttributesBarChartData(transformedData);
            setAttributesBarChartMetrics(metricKeys); // Updated metric keys with formatted titles
        }
        if (selectedMetrics.length > 0 && selectedAttributes.length > 0) {
            processSelectedMetricsIntoBarChartData(selectedMetrics)
        }
    }, [selectedMetrics, selectedAttributes])

    console.log('selected individual attribute', selectedPostAttribute)

    // for pie chart channel breakdown
    const [selectedMetricForPieChart, setSelectedMetricForPieChart] = useState('gravity')
    const [channelBreakdownDataForPieChart, setChannelBreakdownDataForPieChart] = useState([])
    useEffect(() => {
        if (selectedPage === 'Individual Attribute' && selectedPostAttribute !== null) {
            // Reducing posts array to accumulate metrics by channel
            const channelMetrics = selectedPostAttribute.posts.reduce((accumulator, post) => {
                const channel = post.channel;
                const metric = post[selectedMetricForPieChart];
                if (accumulator[channel] === undefined) {
                    accumulator[channel] = metric;
                } else {
                    accumulator[channel] += metric;
                }
                return accumulator;
            }, {});
    
            // Transforming the object into an array of objects for the Nivo pie chart
            const channelBreakdownData = Object.entries(channelMetrics).map(([channel, value]) => ({
                id: channel !== "IG" ? capitaliseAndRemoveUnderscore(channel) : "IG",
                value
            }));
    
            setChannelBreakdownDataForPieChart(channelBreakdownData);
        }
    }, [selectedPage, selectedPostAttribute, selectedMetricForPieChart]);
    
    const [individualPageTopPostsSortByMetric, setIndividualPageTopPostsSortByMetric] = useState(headerColumnNames.find((header) => header.metric === 'gravity'))
    const [individualPageTopPostsSortOrder, setIndividualPageTopPostsSortOrder] = useState('desc')
    const [individualPageTopPosts, setIndividualPageTopPosts] = useState([])
    const [individualPageTopPostsChannelFilter, setIndividualPageTopPostsChannelFilter] = useState(["ig", "fb", "linkedin"])
    const [individualPageTopPostsNumberOfPostsToShow, setIndividualPageTopPostsNumberOfPostsToShow] = useState(10)
    useEffect(() => {
        if (selectedPage === 'Individual Attribute' && selectedPostAttribute !== null) {
            const sortedPosts = selectedPostAttribute.posts
            .filter((post) => {
                return individualPageTopPostsChannelFilter.includes(post.channel.toLowerCase())
            })
            .slice(0, individualPageTopPostsNumberOfPostsToShow)
            .sort((a, b) => {
                if (individualPageTopPostsSortByMetric.metric === 'channel') {
                    if (individualPageTopPostsSortOrder === 'desc') {
                        return b[individualPageTopPostsSortByMetric.metric] - a[individualPageTopPostsSortByMetric.metric]
                    } else {
                        return a[individualPageTopPostsSortByMetric.metric] - b[individualPageTopPostsSortByMetric.metric]
                    }
                } else {
                    if (individualPageTopPostsSortOrder === 'desc') {
                        return b[individualPageTopPostsSortByMetric.metric] - a[individualPageTopPostsSortByMetric.metric]
                    } else {
                        return a[individualPageTopPostsSortByMetric.metric] - b[individualPageTopPostsSortByMetric.metric]
                    }
                }
            })
            console.log('sortedPosts', sortedPosts)
            setIndividualPageTopPosts(sortedPosts)
        }
    }, [selectedPage, selectedPostAttribute, individualPageTopPostsSortByMetric, individualPageTopPostsSortOrder, individualPageTopPostsNumberOfPostsToShow])

    const [selectedAttributeForComparison, setSelectedAttributeForComparison] = useState([])
    const addAttributeForComparison = () => {
        // add a random entry from postAttributesToShow
        setSelectedAttributeForComparison([...selectedAttributeForComparison, postAttributesToShow[Math.floor(Math.random() * postAttributesToShow.length)]] )
    }
    const removeAttributeForComparison = (attribute) => {
        setSelectedAttributeForComparison(selectedAttributeForComparison.filter((selectedAttribute) => {
            return selectedAttribute.attribute !== attribute.attribute
        }))
    }


    console.log('individualPageTopPosts', individualPageTopPosts)

    // loading treshold
    if (loading) return (
        <Typography>
            Loading...
        </Typography>
    )

    return (
        <Box
            id='main-box'
            pr='2rem'
            pl='2rem'
            pt='1.5rem'
            pb='3rem'
            display='flex'
            flexDirection='column'
            minHeight= "80vh"
        >
            <Header title = "Post Attributes Analysis" />
            <Box
                height='40px'
                width='100%'
                display='flex'
                flexDirection='row'
                borderBottom = '3px solid #ccc'
                mt='2rem'
                id='header-box'
            >
                {
                    overviewHeader.map((header, index) => {
                        return (
                            <Box
                                key={index}
                                height='80%'
                                borderRight={index === overviewHeader.length - 1 ? '' : '2px solid #ccc'}
                                mr='2rem'
                                pr='2rem'
                                justifyContent='center'
                                alignItems='center'
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: '#ccc'
                                    }
                                }}
                                onClick={() => setSelectedPage(header.name)}
                            >
                                <Typography
                                    fontSize='1.5rem'
                                    fontWeight={selectedPage === header.name ? 'bold' : 'normal'}
                                >
                                    {header.name}
                                </Typography>
                            </Box>
                        )
                    })
                }
            </Box>
            { selectedPage === 'Overview' &&
                <Box
                    id='overview-box'
                    display='flex'
                    flexDirection='column'
                >
                    <Box
                        mt='2rem'
                        minHeight='300px'
                        maxHeight='500px'
                        height='100%'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        id='table-pie-chart-box'
                        gap='2rem'
                        pb='2rem'
                        borderBottom='4px solid #ccc'
                    >

                        <FormGroup>
                            <FormControlLabel control={<Checkbox defaultChecked/>} label="Include Post Type Attributes" 
                                onChange={
                                    (event) => {
                                        if (event.target.checked){
                                            setPostAttributesToShow(postAttributes)
                                        } else {
                                            setPostAttributesToShow(postAttributes.filter((attribute) => {
                                                return !attribute.isPostType
                                            }))
                                        }
                                    }
                                }
                            />
                        </FormGroup>
                        <TableContainer >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headerColumnNames.map((header, index) => (
                                            <TableCell
                                                key={index}
                                                sx={headerCellSyles}
                                                sortDirection={orderBy === header.metric ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === header.metric}
                                                    direction={orderBy === header.metric ? order : 'asc'}
                                                    onClick={createSortHandler(header.metric)}
                                                >
                                                    {header.columnName}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        postAttributesToShow.map((data, index) => (
                                            <TableDataRow
                                                key={index}
                                                data={data}
                                                setSelectedPage={setSelectedPage}
                                                setSelectedPostAttribute={setSelectedPostAttribute}
                                            />
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box
                        id='bar-chart-box'
                        minHeight='200px'
                        display='flex'
                        width='100%'
                        mt='4rem'
                        flexDirection='column'
                    >
                        <Header title={'Attributes Breakdown'} subtitle='Modify Table to Change Sorting'/>
                        <Box
                            width='100%'
                            height='500px'
                            display='flex'
                            // bgcolor='salmon'
                            mt='1.5rem'
                            flexDirection='column'
                        >
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={headerColumnNames.filter((header) => header.graph !== false)}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.columnName}
                                sx={{ width: '100%' }}
                                onChange={(event, value) => {
                                    setSelectedMetrics(value)
                                }}
                                value={selectedMetrics}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            sx={{ mr: 2 }}
                                            checked={selected}
                                        />
                                        {option.columnName}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} label="Metrics" />
                                )}
                            />
                            <Autocomplete
                                id='bar-amount-autocomplete'
                                // map increments of 5 max is postAttributesToShow.length
                                options = {Array.from({ length: Math.ceil(postAttributesToShow.length / 5) }, (_, i) => Math.min(5 * (i + 1), postAttributesToShow.length))}
                                sx={{ width: '100%', mt:'2rem' }}
                                onChange={(event, value) => {
                                    setNumberOfAttributesToShow(value)
                                }}
                                value={numberOfAttributesToShow}
                                renderInput={(params) => (
                                    <TextField {...params} label="Number of Attributes to Show" />
                                )}
                            />
                            <ResponsiveBar
                                data={attributesBarChartData}
                                keys={attributesBarChartMetrics}
                                indexBy="attribute"
                                groupMode='grouped'
                                margin={{ top: 50, right: 90, bottom: 150, left: 90 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={{ scheme: 'nivo' }}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Attribute',
                                    legendPosition: 'middle',
                                    legendOffset: 32
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-left',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 20,
                                        translateY: 80,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 20,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.85,
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                animate={true}
                                motionStiffness={90}
                                motionDamping={15}
                                tooltip={({ id, value, color }) => (
                                    <div
                                        style={{
                                            color: 'black',
                                            background: 'white',
                                            padding: '5px 10px',
                                            borderRadius: '2px',
                                            fontSize: '14px',
                                            border:`2px solid ${color}`
                                        }}
                                    >
                                        {`${parseCamelCaseToTitle(id)}: ${parseAndFormatNumber(value, 2, true)}`}
                                    </div>
                                )}
                                label  = {({ value }) => ( parseAndFormatNumber(value, 2, true) )}
                            />
                        </Box>
                    </Box>
                </Box>
            }
            {
                selectedPage === 'Individual Attribute' &&
                <Box
                    id='individual-attribute-box'
                    display='flex'
                    flexDirection='column'
                    mt='2rem'
                    minHeight='300px'
                    height='100%'
                    width='100%'
                    pb='2rem'
                >
                    <Box
                        id='summary-stats-row-0'
                        height='200px'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        // alignItems='center'
                        justifyContent='center'
                        pb='0.15rem'
                        borderBottom='4px solid #ccc'
                    >
                        <Box
                            flexDirection='row'
                            width='100%'
                            display='flex'  
                            alignItems='center'
                            height='40%'
                        >
                            {
                                selectingPostAttributeMode === false ?
                                    <Header 
                                        title={selectedPostAttribute.attribute}
                                        subtitle='Summary Stats'
                                    />
                                    :
                                    <Autocomplete
                                        id='post-attribute-autocomplete'
                                        options={postAttributes}
                                        getOptionLabel={(option) => option.attribute}
                                        sx={{ width: '25%' }}
                                        onChange={(event, value) => {
                                            setSelectedPostAttribute(value)
                                            setSelectingPostAttributeMode(false)
                                        }}
                                        value={selectedPostAttribute}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Post Attribute" />
                                        )}
                                    />
                            }
                            <IconButton
                                onClick={() => setSelectingPostAttributeMode(!selectingPostAttributeMode)}
                            >
                                <EditIcon/>
                            </IconButton>
                        </Box>
                        <Box
                            width='100%'
                            alignContent='center'
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                            height='60%'
                        >
                            <Box
                                flexDirection='row'
                                width='80%'
                                display='flex'  
                                alignItems='center'
                                justifyContent='center'
                                
                            >
                                {
                                    headerColumnNames.filter((header) => header.number !== false).map((header, index) => {
                                        return (
                                            <Box
                                                key={index}
                                                display='flex'
                                                flexDirection='column'
                                                alignItems='center'
                                                justifyContent='center'
                                                width='100%'
                                                height='100%'
                                            >
                                                <Typography
                                                    fontSize='1.25rem'
                                                    // fontWeight='bold'
                                                >
                                                    { header.columnName !== 'Posts Made' ?
                                                        parseAndFormatNumber(selectedPostAttribute[header.metric], 2, true)
                                                        :
                                                        selectedPostAttribute.posts.length
                                                    }
                                                </Typography>
                                                <Typography
                                                    fontSize='1rem'
                                                    fontWeight='bold'
                                                >
                                                    {header.columnName}
                                                </Typography>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        id='chart-and-top-posts-box'
                        width='100%'
                        display='grid'
                        gridTemplateColumns='40% 1fr'
                        mt='4rem'
                        minHeight='800px'
                        maxHeight='1600px'
                    >
                        <Box
                            id='chart-box'
                            width='100%'
                            height='100%'
                            display='flex'
                            flexDirection='column'
                            pr='2rem'
                            borderRight='2px solid #ccc'
                        >
                            <Box
                                id='pie-chart-box'
                                height='350px'
                                width='100%'
                                display='flex'
                                flexDirection='column'
                            >
                                <Header title='Channel Breakdown' />
                                <Autocomplete
                                    id='pie-chart-metric-autocomplete'
                                    options={headerColumnNames.filter((header) => header.number !== false)}
                                    getOptionLabel={(option) => option.columnName}
                                    sx={{ width: '50%', mt:'2rem', mb:'1.5rem' }}
                                    onChange={(event, value) => {
                                        setSelectedMetricForPieChart(value.metric)
                                    }}
                                    value={headerColumnNames.find((header) => header.metric === selectedMetricForPieChart)}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Metric" />
                                    )}
                                />
                                <ChannelBreakdownPieChart data={channelBreakdownDataForPieChart} />
                            </Box>
                        </Box>
                        <Box
                            id='attribute-comparison-box'
                            height='100%'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                            pl='2rem'
                        >
                            <Header title='Attribute Comparison' />
                            <Box
                                id='reference-attribtues-box'
                                width='100%'
                                display='flex'
                                mt='1rem'
                                flexDirection='column'
                                borderBottom='2px solid #ccc'
                                pb='2rem'
                                // mb='1rem'
                            >
                                <Typography
                                    fontSize='1.25rem'
                                    fontWeight='bold'
                                    mb='0.25rem'
                                >
                                    {selectedPostAttribute.attribute}
                                </Typography>
                                <Typography
                                    fontSize='0.75rem'
                                    fontWeight='bold'
                                    mb='1rem'
                                >
                                    {"Reference Attribute"}
                                </Typography>
                                <PostMetricsIcons
                                    impression={selectedPostAttribute.impression}
                                    like={selectedPostAttribute.like}
                                    comment={selectedPostAttribute.comment}
                                    share={selectedPostAttribute.share}
                                    save={selectedPostAttribute.save}
                                    gravity={selectedPostAttribute.gravity}
                                />
                            </Box>
                            {
                                selectedAttributeForComparison.length > 0 &&
                                selectedAttributeForComparison.map((attribute, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            id='reference-attribtues-box'
                                            width='100%'
                                            display='flex'
                                            mt='1rem'
                                            flexDirection='column'
                                            borderBottom='2px solid #ccc'
                                            pb='2rem'
                                            // mb='2rem'
                                        >
                                            <Box
                                                display='flex'
                                                flexDirection='row'
                                                alignItems='center'
                                                mb='1rem'
                                            >
                                                { attribute.editAttribute ?
                                                    <Autocomplete
                                                        id='post-attribute-autocomplete'
                                                        options={postAttributes}
                                                        getOptionLabel={(option) => option.attribute}
                                                        sx={{ width: '40%' }}
                                                        onChange={(event, value) => {
                                                            // only return the first match
                                                            const attributeIndex = selectedAttributeForComparison.findIndex((selectedAttribute) => {
                                                                return selectedAttribute.attribute === attribute.attribute
                                                            })
                                                            const newSelectedAttributeForComparison = [...selectedAttributeForComparison]
                                                            newSelectedAttributeForComparison[attributeIndex] = value
                                                            setSelectedAttributeForComparison(newSelectedAttributeForComparison)
                                                        }}
                                                        value={attribute}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Post Attribute" />
                                                        )}
                                                    />
                                                    :
                                                    <Typography
                                                        fontSize='1.25rem'
                                                        fontWeight='bold'
                                                    >
                                                        {attribute.attribute}
                                                    </Typography>
                                                }
                                                <IconButton
                                                    onClick={() => removeAttributeForComparison(attribute)}
                                                >
                                                    <Delete/>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        // set the editAttribtue field of the found postAttributeForComparison to true
                                                        const attributeIndex = selectedAttributeForComparison.findIndex((selectedAttribute) => {
                                                            return selectedAttribute.attribute === attribute.attribute
                                                        })
                                                        const newSelectedAttributeForComparison = [...selectedAttributeForComparison]
                                                        newSelectedAttributeForComparison[attributeIndex].editAttribute = true
                                                        setSelectedAttributeForComparison(newSelectedAttributeForComparison)
                                                    }}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </Box>
                                            <PostMetricsIcons
                                                impression={attribute.impression}
                                                like={attribute.like}
                                                comment={attribute.comment}
                                                share={attribute.share}
                                                save={attribute.save}
                                                gravity={attribute.gravity}
                                                comparison={true}
                                                comparisonImpression={selectedPostAttribute.impression}
                                                comparisonLike={selectedPostAttribute.like}
                                                comparisonComment={selectedPostAttribute.comment}
                                                comparisonShare={selectedPostAttribute.share}
                                                comparisonSave={selectedPostAttribute.save}
                                                comparisonGravity={selectedPostAttribute.gravity}
                                                
                                            />
                                        </Box>
                                    )
                                })
                            }
                            <Box
                                id='add-attribute-box'
                                width='100%'
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                            >
                                <IconButton
                                    onClick={addAttributeForComparison}
                                >
                                    <AddCircleOutlineOutlined/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        id='top-posts-box'
                        height='100%'
                        width='100%'
                        display='flex'
                        flexDirection='column'
                        pl='2rem'
                    >
                        <Header title='Top Posts' />
                        <Box
                            id='top-posts-controls-box'
                            width='100%'
                            display='flex'
                            flexDirection='row'
                            alignItems='center'

                        >
                            <Autocomplete
                                id='sort-option'
                                options={headerColumnNames.filter((header) => header.number !== false)}
                                getOptionLabel={(option) => option.columnName}
                                sx={{ width: '25%', mt:'2rem', mb:'1.5rem', mr:'2rem' }}
                                onChange={(event, value) => {
                                    setIndividualPageTopPostsSortByMetric(value)
                                }}
                                value={individualPageTopPostsSortByMetric}
                                renderInput={(params) => (
                                    <TextField {...params} label="Sort By" />
                                )}
                            />
                            <FormControl>
                                <FormLabel>
                                    Sort Order
                                </FormLabel>
                                <RadioGroup
                                    row
                                >
                                    <FormControlLabel
                                        value='desc'
                                        control={<Radio />}
                                        label='Descending'
                                        checked={individualPageTopPostsSortOrder === 'desc'}
                                        onChange={() => setIndividualPageTopPostsSortOrder('desc')}
                                    />
                                    <FormControlLabel
                                        value='asc'
                                        control={<Radio />}
                                        label='Ascending'
                                        checked={individualPageTopPostsSortOrder === 'asc'}
                                        onChange={() => setIndividualPageTopPostsSortOrder('asc')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        {
                            selectedPostAttribute !== null &&
                            individualPageTopPosts.length > 0 &&
                            individualPageTopPosts.map((post, index) => {
                                return(
                                    <Box
                                        key={index}
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        height='100%'
                                        pb='2rem'
                                        mb='1rem'
                                        mt='1rem'
                                        borderBottom='2px solid #ccc'
                                    >
                                        <Box
                                            width='100%'
                                            display='flex'
                                            flexDirection='row'
                                            alignItems='center'
                                        >
                                            <ChannelLogo channel={post.channel}/>
                                            <Typography
                                                fontSize='1rem'
                                                fontWeight='bold'
                                                ml='1rem'
                                            >
                                                {(post.channel !== "IG") ? capitaliseAndRemoveUnderscore(post.channel) : 'Instagram'}
                                            </Typography>
                                            <IconButton
                                                onClick={() => {
                                                    const url = `/individual-post/${post.channel}`;
                                                        const state = { id: `${post.id}` };
                                                        // Serialize the state object into a query string
                                                        const stateQueryString = new URLSearchParams(state).toString();
                                                        // Construct the full URL
                                                        const fullUrl = `${window.location.origin}${url}?${stateQueryString}`;
                                                        // Open in a new tab
                                                        window.open(fullUrl, '_blank');
                                                        }
                                                }
                                            >
                                                <OpenInNewIcon/>
                                            </IconButton>
                                        </Box>
                                        <Typography
                                            variant='subtitle2'
                                            mb='1rem'
                                        >
                                            {convertDate(post.created_time, 'DMYHM')}
                                        </Typography>
                                        <Typography
                                            mb='1.25rem'
                                        >
                                            {post.caption.length > 300 ? post.caption.slice(0, 300) + '...' : post.caption}
                                        </Typography>
                                        <PostMetricsIcons
                                            impression={post.impression}
                                            like={post.like}
                                            comment={post.comment}
                                            share={post.share}
                                            save={post.save}
                                            gravity={post.gravity}
                                        />
                                        
                                    </Box>
                                )
                        }
                        )}
                        <Box
                            id='top-posts-controls-box'
                            width='100%'
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            mb='2rem'
                        >
                            <Typography>
                                Showing {individualPageTopPosts.length} of {selectedPostAttribute.posts.length} posts
                            </Typography>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    setIndividualPageTopPostsNumberOfPostsToShow(individualPageTopPostsNumberOfPostsToShow + 10)
                                }}
                            >
                                Show More
                            </Button>
                        </Box>
                    </Box>
                </Box>
                
            }
        </Box> 
    )
}

export default PostAttributesAnalysis
