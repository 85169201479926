import React, { useEffect } from 'react'
import { useState } from 'react'
import Header from 'components/Header'
import { useTheme } from '@emotion/react'
import { ListItemAvatar, Typography, useMediaQuery, Rating, Chip, Checkbox, Button, IconButton, Tooltip } from '@mui/material'
import { Box,  List, ListItem, ListItemText, Autocomplete, TextField } from '@mui/material'
import { TroubleshootOutlined, 
ThumbUpOutlined,
CallMadeOutlined,
TouchAppOutlined,
ChatBubbleOutlineOutlined,
Delete,

} from '@mui/icons-material'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';


import { getFacebookPosts, sendFacebookPostAttribute, deleteFacebookPostAttribute, fetchComments, replyToCommentGivenACommentId, fetchPageAccessToken } from 'state/api'
import FacebookPost from 'components/FacebookPost'
import { Link, useLocation, useParams } from 'react-router-dom'
import { checkAuth } from 'lib/auth'
import InstagramPost from 'components/InstagramPost'
import LinkedInPost from 'components/LinkedinPost'
import { convertDate, convertDateToDMYWhereMonthIsShortened } from 'lib/helperFunction'
// import { Navigate } from 'react-router-dom'

import CommentIcon from '@mui/icons-material/Comment';
import { depreciatedUsers } from 'lib/helperList'


const IndividualPost = () => {

    // const notAdmin = <Navigate to ='/dashboard'/> 
    // const unauth = <Navigate to = '/'/>

    const auth = checkAuth()
    const org = auth ? auth.org :undefined

    // options for adding post attributes
    const allAttributes = [
        "Primary Income",
        "Shop",
        "Loyalty",
        "Food and Beverages",
        "Behind the Scenes",
        "Articles",
        "Long-form Content",
        "Teasers",
        "User Generated Content",
        "Show Content",
        "Public News Stories",
        "Brand News",
        "Reviews",
        "Social Stories",
        "Public Events",
        "Brand Events",
        "Holidays and Moments",
        "Building Tours",
        "Interviews",
        "Curator's Corner",
        "Art in Nature",
        "Up Close and Personal",
        "Art and Music",
        "Color Pop",
        "Art and Fashion",
        "Art and Literature",
        "Iconic Art",
        "Art and Architecture",
        "Art and Design",
        "Art and Food",
        "Art and History",
        "Art in the Community",
        "Art and Travel",
        "Art and Technology",
        "Inspiring Stories",
        "Fun Facts",
        "Virtual Tours",
        "History Highlights",
        "Innovative Technology",
        "Nature and Wildlife",
        "Local Events",
        "Community Outreach",
        "Museum Collections"
    ]
    const zeroGravityPostAttributes = ["Moon Shot", "Community Engagement", "Save CTA", "Share CTA", "Comment CTA", "Like CTA", "Merch", "Photo Essays", "Collaborative Posts", "Video Carousels", "Team Posts", "Articles", "Long Form", "Public News", "Brand News", "Public Events", "Brand Events", "Influencer Post", "PR", "Awards", "Entertainment", "Informational", "Inspirational", "Video Interviews", "Hiring", "Topical"]
    const postAttributes = org === 'zg' ? zeroGravityPostAttributes : allAttributes
    // icons for selecting post attributes dropdown
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />


    const theme = useTheme()
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)")

    // for redirected page from the all facebook posts page
    const location = useLocation()
    console.log('location', location)

    // this acts as an anchor in which all the metrics displayed on the page will refer to
    const [activePostId, setActivePostId] = useState('');

    // get all facebook posts data
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // this is the attributes stored by the autocomplete, i.e. new attribute
    const [selectedAttributes,  setSelectedAttributes] = useState([])

    // this sets the active post attributes according to the activePostID,
    // which is the only metric that uses useState to ease manipulation of the array
    const [activePostAttributes, setActivePostAttributes] = useState([])

    // main use effect, fetch the data and set all the main useStates, i.e.
    // activePostId, activePostAttributes, data, and location

    // checking the url to see which channel is selected
    const { channel } = useParams()
    useEffect(() => {
        // check if redirected from the all facebook posts page
        const redirectedPage = location.state?.id ?? ''
        
        // api that fetches the data
        getFacebookPosts(org, channel)
        .then(response => {
        const posts = response.data;
        setData(posts);
        // if redirected, sets the acvtive post id from the redirected page, 
        // else sets the active post id to be the latest post, the fetched
        // data have been sorted by the latest post, hence [0]
        setActivePostId(redirectedPage === '' ? posts[0].id : redirectedPage)
        console.log('response', response.data)
        setActivePostAttributes(redirectedPage === '' ? 
                                posts[0].attribute : 
                                response.data.find((item) => item.id === redirectedPage)?.attribute ?? '')
        setIsLoading(false)
        setFetchedComments([])
        })
        .catch(error => {
            console.log(error);
            setIsLoading(false);
        });
    }, [channel]); //eslint-disable-line

    // this is for for the post attribute useState, essentially refreshes the post attributes
    // everytime the avtivePostId changes
    useEffect(()=>{
        setActivePostAttributes(data.find((item) => item.id === activePostId)?.attribute ?? ' ')
        setIsLoading(false)
    },[activePostId]) //eslint-disable-line

    // fetches the postUrl for the embedded post
    let postUrl = isLoading ? '0' : data.find((item) => item.id === activePostId)?.permalink ?? '0' //eslint-disable-line

    // this is for deleting the post attributes, remove the post attributes from the active ones
    // and send a post request to the database to delete them
    const handleDelete = (attr) => {
        setActivePostAttributes((prevAttrs) => prevAttrs.filter((a) => a !== attr))
        deleteFacebookPostAttribute(org,{postId: activePostId, attributeToRemove:attr,},channel)
        }


    console.log('post id', activePostId)

    const [fetchedComments, setFetchedComments] = useState([])
    const [nextPage, setNextPage] = useState(null)

    const fetchPostComments = (nextPage, replace) => {
        let data = {
            org: org,
            postId: activePostId,
            channel: channel,
        }
        if (nextPage){
            data = {
                ...data,
                nextPage: nextPage
            }
        }

        fetchComments(data)
            .then(response => {
                console.log('response', response.data)
                if (response.data.comments.length === 0 || !response.data.comments){
                    setFetchedComments([])
                } else if (fetchedComments.length > 0){
                    if (replace === 'replace'){
                        setFetchedComments(response.data.comments)
                    } else {
                        // check duplicate comments
                        const newComments = response.data.comments.filter(comment => !fetchedComments.find((item) => item.id === comment.id))
                        setFetchedComments([...fetchedComments, ...newComments])
                    }
                } else {
                    setFetchedComments(response.data.comments)
                }
                setNextPage(response.data.next)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const commentIconButtons = ({commentId}) => {
        return (
            <Box
                id='icon-button-box'
                display='flex'
                flexDirection='row'
            >
                <Tooltip title='Reply'>
                    <IconButton
                        onClick={() => handleShowCommentTextfield(commentId)}
                    >
                        <CommentIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Hide'>
                    <IconButton>
                        <VisibilityOutlinedIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete'>
                    <IconButton>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    useEffect(() => {
        if (activePostId) {
            fetchPostComments(null, 'replace')
        }
    }, [activePostId]);

    const [showCommentTextfield, setShowCommentTextfield] = useState(null)
    const [commentMessage, setCommentMessage] = useState('')

    const handleShowCommentTextfield = (commentId) => {
        setShowCommentTextfield(commentId)
    }

    const handleReplyToComment = ({commentId}) => {
        let data = {
            org: org,
            commentId: commentId,
            channel: channel,            
            comment: commentMessage,
            postId: activePostId
        }
        replyToCommentGivenACommentId(data)
            .then(response => {
                if (response.status === 200){
                    // append the new comment to the fetched comments
                    const newComments = fetchedComments.map((comment) => {
                        if (comment.id === commentId){
                            return {
                                ...comment,
                                replies: {
                                    ...comment.replies,
                                    data: [...comment.replies.data, {
                                        id: null,
                                        text: commentMessage,
                                        timestamp: new Date().toISOString(),
                                    }]
                                }
                            }
                        } else {
                            return comment
                        }
                    })
                    setFetchedComments(newComments)
                    setShowCommentTextfield(null)
                    setCommentMessage('')
                }
            })
            .catch(error => {
                console.log(error);
            });
    }


    const [pageAccessToken, setPageAccessToken] = useState(null)
    
    useEffect(() => {
        if (!depreciatedUsers.includes(org) || org === 'zg') {
            fetchPageAccessToken(org, channel)
            .then(response => {
                console.log('page access token', response)
                setPageAccessToken(response.data)
            })
            .catch(error => {
                console.log(error)
            })
        }
    }, [org, channel])


    const [stateIdButNoPostFound, setStateIdButNoPostFound] = useState(false)
    useEffect(() => {
        if (data.length > 0 && data){
            if (location.state !== null && !data.find((item) => item.id === location.state?.id)){
                console.log('state id not found use effect if')
                setStateIdButNoPostFound(true)
                setIsLoading(false)
            } else {
                // console.log('data', data)
                console.log('state id not found use effect else')
                setStateIdButNoPostFound(false)
                setActivePostId(data[0].id)
            }
        }
    }, [location.state?.id, data])
    

    console.log('fetched comments', fetchedComments)
    console.log('selected post', selectedAttributes)

    if (isLoading || data.length === 0  || !activePostId || (activePostAttributes.length === 0 && !stateIdButNoPostFound)){
        return <Typography>Loading...</Typography>
    }

    if (stateIdButNoPostFound){
        return (
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                height='40vh'
                flexDirection='column'
                gap='1.5rem'
            >
                <Typography
                    variant='h3'
                >
                    Post not found
                </Typography>
                <Typography>
                    It might take 15 minutes for new created post to appear. Please wait or contact support
                </Typography>
                <Autocomplete
                    disableClearable
                    options={
                        isLoading
                        ? ['Loading']
                        : data.map(caption => {
                            return { id: caption.id, label: caption.caption.substring(0, 150) };
                            })
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Select a post"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                        />
                        )}
                    sx={{ width: 800 }}
                    // sets the activePostId based on the search abr
                    onChange={(event, newValue) => {
                        if (newValue !== null) {
                        setActivePostId(newValue.id);
                        } else {
                        setActivePostId(null);
                        }
                        // setIsLoading(true)
                        setStateIdButNoPostFound(false)
                    }}
                    />
                
            </Box>
        )
    }
    let title = channel === 'facebook' ? 'Facebook' : channel === 'ig' ? 'Instagram' : channel === 'linkedin' ? 'LinkedIn' : null
    title = title + ' Post'

    
 

    return (
        <Box m="1.5rem 2.5rem">
            <Box
                sx={{
            display:"flex",
            flexDirection:'row'
            }}
        >
                <Box
                    mr='50px'
            >
                    <Header title={title} subtitle = "Individual Post Data" />
                </Box>
                <Box>
                    {/* search bar to select the active post based on caption */}
                    <Autocomplete
                        disableClearable
                        options={
                    isLoading
                    ? ['Loading']
                    : data.map(caption => {
                        return { id: caption.id, label: caption.caption.substring(0, 150) };
                        })
                }
                        renderInput={params => (
                            <TextField
                                {...params}
                                label="Select a post"
                                InputProps={{
                        ...params.InputProps,
                        type: 'search',
                    }}
                    />
                )}
                        sx={{ width: 800 }}
                // sets the activePostId based on the search abr
                        onChange={(event, newValue) => {
                    if (newValue !== null) {
                    setActivePostId(newValue.id);
                    } else {
                    setActivePostId(null);
                    }
                }}
                />
                </Box>
            </Box>

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="180px"
                gap="20px"
                sx={{
            "& > div" : { gridColumn : isNonMediumScreens ? undefined: "span 12"}
            }}
        >
                {/* EMBEDDED POSTS */}
                <Box
                    id="embedded post main box"
                    gridColumn="span 6"
                    gridRow="span 2"
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    p="1rem 2rem"
                    flex = "1 1 100%"
                    backgroundColor={theme.palette.background.alt}
                    sx={{boxShadow:4}}
            >
                    <Box
                        id='embedded post'
                        display='flex'
                        justifyContent="center"
                        sx={{overflowY:'auto'}}
            >
                        {/* If no activePostId, dont render anything, else render the facebook post */}
                        {activePostId === '' ? undefined :
                            channel === 'facebook' ?
                                <FacebookPost postId={activePostId} height='300' width='500' pageAccessToken={pageAccessToken}/>
                            : channel === 'ig' ?
                                <InstagramPost postId={activePostId} pageAccessToken={pageAccessToken}/>
                            : channel === 'linkedin' ?
                                <LinkedInPost postId={activePostId} height='300' width='500' pageAccessToken={pageAccessToken}/>
                            : null
                        }
                    </Box>
                    <Box
                        id='star rating'
                        display="flex" // add 'flex' display property
                        justifyContent="center" // center horizontally
                        alignItems="center" // center vertically
            >
                        {/* STAR RATING */}
                        <Rating
                            value={
                        isLoading
                            ? ' '
                            : data.find((item) => item.id === activePostId)?.star_rating ?? ' '
                        } 
                            precision={0.5}
                            readOnly
                />
                    </Box>
                </Box>
            
                {/* END OF EMBEDDED POST */}

                {/* POST ATTRIBUTES */}
                <Box
                    id='post attributes main box'
                    gridColumn="span 6"
                    gridRow="span 2"
                    display="grid"
                    gridTemplateColumns="repeat(2, 1fr)"
                    gridTemplateRows="auto 0.6fr 1fr"
                    flexDirection="column"
                // p="1rem 2rem"
                    flex="1 1 100%"
                    backgroundColor={theme.palette.background.alt}
                // borderRadius="0.55rem"
                    sx={{boxShadow:4}}
            >
                    <Box
                        id='post attributes header box'
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        flex = '0 0 auto'
                        pl='1.25rem'
                        pt= '0.75rem'
                        pb= '0.5rem'
                        gridColumn="1 / -1" 
            >
                        <Header title="Post Attributes" subtitle='Add or Remove Post Attributes'/>
                    </Box>
                    <Box
                        id='post attributes top half'
                        gridTemplateColumns="repeat(2, 1fr)"
                        gridColumn="span 2"
                        display="flex"
                        flexDirection="row"
                        pl='1.25rem'
                        pr='1.25rem'
                        flex="1 1 100%"
                        borderRadius="0.55rem"
                        maxHeight="10rem"
                        flexWrap = "wrap"
                        sx={{overflowY:'scroll'}}
            > 
                        {/* MAP POST ATTRIBUTES TO CHIPS */}
                        {activePostAttributes.map((attr)=>(
                            <Chip 
                                key={attr} 
                                label={attr}
                                onDelete={() => handleDelete(attr)}
                    />
                ))}
                    </Box>

                    <Box
                        id='post attributes bottom half'
                        gridColumn="span 2"
                        gridRow="span 1"
                        display="flex"
                        flexDirection="column"
                        pt="1.5rem"
                        pl= "1.25rem"
                        pr= "1.25rem"
                        pb= '0.75rem'
                        flex="1 1 100%"
                        borderRadius="0.55rem"
            >
                        <Autocomplete
                            id='add post attributes'
                            multiple
                            options={postAttributes}
                            limitTags={3}
                            getOptionLabel={(option) => option}
                            disableCloseOnSelect
                // render the options 
                            renderOption={(props, option, {selected}) => (
                                <Box 
                                    sx={{ backgroundColor: theme.palette.background.alt }}
                                >
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{marginRight:8}}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                </Box>
                                )}
                            renderInput={(params) => (
                                <TextField {...params} label= "Select an Attribute" placeholder="Attributes"/>
                                )}
                            sx={{pb:'1rem'}}
                // if post attributes already in the options, disable them
                            getOptionDisabled={(option) => {
                    const filteredArray = postAttributes.filter(attr => data.find((item) => item.id === activePostId)?.attribute?.includes(attr));
                    return filteredArray.includes(option);
                }}
                // this push the selected tags to the selectedAttributes array
                // theres another function later that pushes this array to the database,
                // so this bit acts as a staging
                            onChange={(event, newValue) => {
                    setSelectedAttributes(newValue)
                }}
                >
                        </Autocomplete>
                        <Button 
                            id="button to send data to database"
                            variant="contained" color="secondary"              
                            onClick={() => {
                            // first it creates a filtered attributes, i.e. selected attributes that dont appear in the active attributes
                            const filteredAttributes = selectedAttributes.filter(attribute => !activePostAttributes.includes(attribute));
                            // then it sets up the ac
                            setActivePostAttributes([...activePostAttributes, ...filteredAttributes])
                            const attributeToUpdate = {
                            id: activePostId,
                            newAttribute: selectedAttributes, ...activePostAttributes
                            }
                            sendFacebookPostAttribute(attributeToUpdate, org, channel)              
                        }}
                        >
                            ADD ATTRIBUTES
                        </Button>
                    </Box>
                    {/* END OF POST ATTRIBUTES */}


                </Box>
                {/* this section is a little bit messy, we might be able to create a component */}
                {/* that can just takes argument to make this section clearer, such as the statbox */}
                {/* METRICS */}
                <Box
                    id='embedded post metric box'
                    gridColumn="span 6"
                    gridRow="span 1"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
            // p="1.5rem 2rem"
                    flex = "1 1 100%"
                    backgroundColor={theme.palette.background.alt}
            // borderRadius="0.55rem"
                    sx={{boxShadow:4}}
                >
                    <Box
                        gridColumn="span 1"
                        gridRow="span 1"
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        flex = "1 1 100%"
                        borderRadius="0.5rem"
                        backgroundColor={theme.palette[100]}
                    >
                        <List 
                            p="0.5rem 1rem"
                            dense='true'
                        >
                            {/* GRAVITY SCORE */}
                            <ListItem>
                                <ListItemAvatar>
                                    <TroubleshootOutlined />
                                </ListItemAvatar>
                                <ListItemText primary="Gravity" 
                                    secondary={
                            isLoading
                                ? ' '
                                : data.find((item) => item.id === activePostId)?.gravity ?? ' '
                            } />
                            </ListItem>

                            {/* IMPRESSION */}
                            <ListItem>
                                <ListItemAvatar>
                                    <VisibilityOutlinedIcon />
                                </ListItemAvatar>
                                <ListItemText primary="Impression" 
                                    secondary={
                                    isLoading
                                        ? ' '
                                        : data.find((item) => item.id === activePostId)?.impression ?? ' '
                                    } />
                            </ListItem>
                        </List>
                    </Box>
                    <Box
                        gridColumn="span 1"
                        gridRow="span 1"
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        flex = "1 1 100%"
                        borderRadius="0.5rem"
                        backgroundColor={theme.palette[100]}
                    >
                        <List 
                            p="0.5rem 1rem"
                            dense='true'
                        >
                            {/* LIKE */}
                            <ListItem>
                                <ListItemAvatar>
                                    <ThumbUpOutlined />
                                </ListItemAvatar>
                                <ListItemText primary="Like" 
                                    secondary={
                                    isLoading
                                        ? ' '
                                        : data.find((item) => item.id === activePostId)?.like ?? ' '
                                    } />
                            </ListItem>
                            {/* SHARE */}
                            <ListItem>
                                <ListItemAvatar>
                                    <CallMadeOutlined />
                                </ListItemAvatar>
                                <ListItemText primary="Share" 
                                    secondary={
                                        isLoading
                                            ? ' '
                                            : data.find((item) => item.id === activePostId)?.share ?? ' '
                                        } />
                            </ListItem>
                        </List>
                    </Box>
                    <Box
                        gridColumn="span 1"
                        gridRow="span 1"
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                        flex = "1 1 100%"
                        borderRadius="0.5rem"
                        backgroundColor={theme.palette[100]}
            >
                        <List 
                            p="0.5rem 1rem"
                            dense='true'
                >
                            {/* CLICK */}
                            <ListItem >
                                <ListItemAvatar>
                                    <TouchAppOutlined />
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={channel=== 'ig' ? 'Save' : 'Click'}
                                    secondary={
                            isLoading
                                ? ' '
                                : channel === 'ig' 
                                    ? data.find((item) => item.id === activePostId)?.saved ?? ' ' 
                                    : data.find((item) => item.id === activePostId)?.click ?? ' '
                        } 
                    />
                            </ListItem>
                            {/* COMMENT */}
                            <ListItem
                >
                                <ListItemAvatar>
                                    <ChatBubbleOutlineOutlined />
                                </ListItemAvatar>
                                <ListItemText primary="Comment" 
                                    secondary={
                    isLoading
                        ? ' '
                        : data.find((item) => item.id === activePostId)?.comment ?? ' '
                    } />
                            </ListItem>
                        </List>
                    </Box>
                    {/* END OF METRICS */}


                </Box>
            </Box>
            <Box
                id='comment-box'
                mt='4rem'
                display="flex"
                minHeight='100px'
                flexDirection='column'
                backgroundColor={theme.palette.background.alt}
                sx={{boxShadow:4}}
                p='2rem'
            >
                <Header title={"Comments"}/>
                {
                    (fetchedComments !== null && fetchedComments?.length ===0) ?
                        <Typography>No Comments Found</Typography>
                    :
                    fetchedComments === null ?
                        <Typography>Unknown error, please contact support andreww@10point6.com</Typography>
                    :
                    fetchedComments && fetchedComments
                    .filter((comment) => comment.text !== '')
                    .map((comment,index) => 
                        <Box
                            key={index}
                            display='flex'
                            width='60%'
                            flexDirection='column'
                            p='1rem'
                            borderBottom='2px solid #ccc'
                            mb='2rem'
                        >
                            <Typography variant='h6' fontWeight='bold'>{comment.username }</Typography>
                            <Typography variant='subtitle1'>{convertDate(comment.timestamp, 'DMYHM')}</Typography>
                            <Typography ml='2rem'>{comment.text}</Typography>
                            {commentIconButtons({commentId: comment.id})}
                            {
                                comment.id === showCommentTextfield &&
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='70%'
                                    mt='1rem'
                                    mb='2rem'
                                >
                                    <TextField
                                        label="Reply"
                                        multiline
                                        rows={4}
                                        defaultValue=""
                                        onChange={(event) => setCommentMessage(event.target.value)}
                                    />
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleReplyToComment({commentId: comment.id})}
                                    >
                                        Reply
                                    </Button>
                                </Box>
                            }
                            {
                                comment.replies && comment.replies.data && comment.replies.data.map((reply, index) =>
                                    <Box
                                        key={index}
                                        display='flex'
                                        width='60%'
                                        flexDirection='column'
                                        p='1rem'
                                        borderLeft='2px solid #ccc'
                                        mt='1rem'
                                        mb='2rem'
                                        ml='4rem'
                                    >
                                        <Typography variant='h6' fontWeight='bold'>{reply.username }</Typography>
                                        <Typography variant='subtitle1'>{convertDate(reply.timestamp, 'DMYHM')}</Typography>
                                        <Typography ml='2rem'>{reply.text}</Typography>
                                    </Box>
                                )
                            }
                        </Box>
                    )
                }
                { nextPage &&
                    <Button
                        onClick={() =>{fetchPostComments(nextPage)}}
                        variant="contained" 
                        color="secondary"              

                    >
                        Show More
                    </Button>
                }
            </Box>
        </Box>
    )
    }

    export default IndividualPost